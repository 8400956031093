import React from "react";
import Header from "../components/Header";
import Navigatebar from "../components/Navigatebar";
import { Flex, Heading, Container } from "@chakra-ui/react";
import AlarmHistoryTable from "../components/alarm/AlarmHistoryTable";
import { useTranslation } from "react-i18next";

const AlarmHistory = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Navigatebar page={t("noti_history")} />
      <Container maxW="1200px" mt={10}>
        <Heading fontSize="36">{t("noti_history")}</Heading>
        <Flex justify="center" w="auto">
          <AlarmHistoryTable />
        </Flex>
      </Container>
    </>
  );
};

export default AlarmHistory;
