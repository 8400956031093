import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from "react-i18next";

const Navigatebar = (props) => {
  const auth = React.useContext(AuthContext);
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();

  function navigateByRole() {

    switch (auth.user.role) {
      case "admin":
        navigate("/selectposition/" + location.search);
        break;
      case "security":
        navigate("/alarmdetectionboard/" + location.search);
        break;
      case "niti":
        navigate("/alarmdetectionboard/" + location.search);
        break;
      case "user_management":
        navigate("/dashboard/" + location.search);
        break;
      case "lift":
        navigate("/liftmonitor/" + location.search);
        break;
      default:
        break;
    }
  }

  return (
    <Breadcrumb
      mt={5}
      ml={5}
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          onClick={() => navigateByRole()}
        >
          {t("main_page")}
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <BreadcrumbLink>{props.page}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default Navigatebar;
