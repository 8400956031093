import { Box, Select, Flex, Input, Text, Button, FormLabel, Container, useToast, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { FieldikPassword, Fieldik } from '../../components/form/Fieldik'
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import ModalPasswordChange from "../../components/modal/ModalPasswordChange"
import { BsKey } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useErrorMessages } from '../enum/errorMessages'

const UsersSelect = ({ users }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const [user, setUser] = useState(undefined);
  const { t } = useTranslation();
  const errorMessages = useErrorMessages();

  async function handleUserSubmit(values, actions) {
    const editUser = {
      code: values.code,
      name: values.name,
    };
    try {
      const result = await axios.put(process.env.REACT_APP_BASE_URL + "/users/" + user.username, editUser, { withCredentials: true })
      if (result.status === 200) {
        toast({ position: 'top-center', title: t("edit_data_success"), description: t("username")+" " + user.username, status: 'success', duration: 5000, isClosable: true, });
        navigate('/dashboard');
      }
    }
    catch (err) {
      console.error(err);
      if (err.response) {
        if (err.response?.status === 401) {
          redirectToLogin();
        }
        else {
          toast({ position: 'top-center', title: t("edit_data_failed"), description: `${err.response?.data?.message}`, status: 'error', duration: 5000, isClosable: true, });
        }
      }
    }
  }

  async function handlePasswordSubmit(values, actions) {
    try {
      const newPassword = { password: values.password };
      const updateResponse = await axios.put(process.env.REACT_APP_BASE_URL + "/users/" + user.username + "/resetpassword", newPassword, { withCredentials: true });
      if (updateResponse.status == 200) {
        toast({ position: 'top-center', title: t("change_password_success"), status: 'success', duration: 5000, isClosable: true, });
        actions.setSubmitting(false);
        onClose();
      }
    }
    catch (err) {
      console.log(err);

      if (err.response) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        else {
          toast({ position: 'top-center', title: t("change_password_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
        }
      }
    }
  }

  function redirectToLogin() {
    navigate('/Login');
  }


  function validateUserCode(value) {
    let error
    if (!value) {
      error = errorMessages.ERROR_USER_CODE_EMPTY
    }
    return error;
  }
  function validateName(value) {
    let error
    if (!value) {
      error = errorMessages.ERROR_USER_NAME_EMPTY
    }
    return error;
  }

  return (
    <>
      <Text fontSize="20px" mb={5}>
        {t("manage_niti_accounts")}
      </Text>
      <Formik enableReintialize initialValues={{ code: '', name: '', password: '', vpassword: '' }} onSubmit={handleUserSubmit}>
        {(props) => (
          <>
            <Select onClick={(e) => {
              const user = users.find(u => u._id === e.target.value);
              setUser(user);
              if (user) {
                props.setFieldValue('code', user.code);
                props.setFieldValue('name', user.name);
              }
              else {
                props.setFieldValue('code', '');
                props.setFieldValue('name', '');
              }


            }}>
              <option value=''>{t("select_niti")}</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>{user.username}</option>
              ))}
            </Select>
            {user && <Form>
              <Flex justify="center">
                <Box w="50%" mr={5}>
                  <Fieldik name="code" validate={validateUserCode} type="input" label={t("id_code")} variant="flushed"></Fieldik>
                </Box>
                <Box w="50%">
                  <Fieldik name="name" validate={validateName} type="input" label={t("name_surname")} variant="flushed"></Fieldik>
                </Box>
              </Flex>
              <ModalPasswordChange header={t("change_user_password") + " " + user.username} isOpen={isOpen} onClose={onClose} onSubmit={(values, actions) => handlePasswordSubmit(values, actions)}>
              </ModalPasswordChange>
              <Flex mt={5} justifyContent="end">
                <Button leftIcon={<BsKey />} mt={1} colorScheme='blue' mr={3} onClick={() => onOpen()}>{t("change_password")}</Button>
                <Button mt={1} type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}
                  disabled={!(props.isValid && props.dirty)}
                >
                  {t("submit")}</Button>
              </Flex>
            </Form>}
          </>
        )}
      </Formik>
    </>
  )
}

export default UsersSelect;

UsersSelect.propTypes = {
  users: PropTypes.any,
  onClick: PropTypes.func,
  onValueChanged: PropTypes.func,
}