import { Box,Tooltip } from "@chakra-ui/react";

const offsetX = -25;
const offsetY = -25;

export const Marker = ({marker, width, height, precision}) => {
    return (
        <Tooltip hasArrow label={"address " + marker.address + "\n" + "macaddress " + marker.macaddress}>
        <Box
        className={precision?"precision_marker":"marker"}
        // border="2px solid"
        // borderColor="green.400"
        w="50px"
        h="50px"
        position="absolute"
        top={`${(Number(marker.x) * height) / 100 + offsetX}px`}
        left={`${(Number(marker.y) * width) / 100 + offsetY}px`}
      >
        </Box>
        </Tooltip>
      
    )
}

export default Marker;