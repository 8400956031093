import 'core-js/es/map'; // <-- added this line after installed packages
import 'core-js/es/set'; // <-- added this line after installed packages
import 'raf/polyfill'; // <-- added this line after installed packages
import React from "react";
import ReactDOM from "react-dom/client";
import ReactDom from 'react-dom';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ColorModeScript } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import "./font.css";
import {Footer} from './components/Footer'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
      {ReactDom.createPortal(<Footer />,document.getElementById('footer'))}
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
