import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Grid,
  GridItem,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";

const minute_mill = 1000 * 60

export function Clock(){
    const [date, setDate] = React.useState(new Date());

    React.useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), minute_mill);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
    ];
    const [hour, minutes, seconds] = [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    ];

    return (
        <Flex w="100px" flexDir="column">
                  <Box>
                    {day}/{month+1}/{year}
                  </Box>
                  <Box fontSize="2em">
                    {hour}:{(minutes < 10 ? "0" : "") + minutes}
                  </Box>
                </Flex>
    )
}