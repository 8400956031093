import React from "react";
import { Box, Image } from "@chakra-ui/react";
import Header from "../components/Header";

const property = {
  imageUrl:
    "https://upload.wikimedia.org/wikipedia/commons/3/33/%E0%B9%81%E0%B8%AA%E0%B8%99%E0%B8%AA%E0%B8%B4%E0%B8%A3%E0%B8%B4-%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94-%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99-logo-en.jpg",
  imageAlt: "AheadAll",
  imageUrl2:
    "https://onlinebooking.sansiri.com/stocks/project/d850x550/tw/6l/fuqctw6lp8/master-plan-02.jpg",
  imageAlt2: "AheadAll",
};
const DetectionBoard = () => {
  return (
    <>
      <Header />
      <Box align="center">
        <Image h="auto" src={property.imageUrl2} alt={property.imageAlt2} />
      </Box>
    </>
  );
};

export default DetectionBoard;
