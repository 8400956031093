import React from "react";
import Header from "../components/Header";
import AccountTable from "../components/AccountTable";
import Navigatebar from "../components/Navigatebar";
import { Flex, Heading, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Accountmanagment = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Navigatebar page={t("user_management")} />
      <Container maxW="1200px" mt={10}>
        <Heading fontSize="36">{t("user_management")}</Heading>
        <Flex justify="center" w="auto">
          <AccountTable />
        </Flex>
      </Container>
    </>
  );
};

export default Accountmanagment;
