import {Box, Button, Container,Heading, Text, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalFooter, ModalBody, ModalCloseButton, Flex, FormLabel, Input, RadioGroup, Stack , Radio,Select,FormControl,FormErrorMessage } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/react";
import React from 'react';
import ModalBase from '../modal/ModalBase';
import { Formik ,Form,Field } from 'formik';
import {Fieldik, FieldikRadio, FieldikSelect} from '../form/Fieldik'
import * as validator from '../validator/validator'
import { useTranslation } from "react-i18next";

const AcknowledgeAlarmForm = ({users, user, isOpen, onSubmit, cancelClickCallback}) => {
  const { t } = useTranslation();
  const {
    validateInspector,
    validateReason
  } = validator.useValidation();

    return (
      <ModalBase onClose={() => cancelClickCallback()} isOpen={isOpen} isCentered={true}
      header={
        <>
          <chakra.span color="teal">{t("notification_record")}</chakra.span> 
        </>}
    >
      {<Formik initialValues={{inspectorId: '', reason: '', remark: ''}} onSubmit={(values,actions) => onSubmit(user,values,actions)}>
      {(props) => (
        <Form>
          <Container maxW="2xl">
            <Flex align="center">
              <Heading mr={5} fontSize="18px" color="gray">
              {t("please_input_details")}
              </Heading>
            </Flex>
            <Field name='inspectorId' validate={validateInspector}>
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.inspector}>
              {users && <Select m={2} onChange={(e) => props.setFieldValue('inspectorId', e.target.value, true)}>
                <option value=''>{t("please_select_inspector")}</option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>{user.username}</option>
              ))}
              </Select>}
              <FormErrorMessage>{form.errors.inspector}</FormErrorMessage>
              </FormControl>
            )}</Field>

            <Text mr={3}>{t("account_recorder")} : {user.username}</Text>
            <Flex align="center">
            <Text mr={3} color="gray">{t("input_reason")}</Text>
            </Flex>
            <Stack role="group" direction='column'>
              <FieldikRadio validate={validateReason} name="reason" value={t("system_error")}></FieldikRadio>
              <FieldikRadio validate={validateReason} name="reason" value={t("intruder")}></FieldikRadio>
              <FieldikRadio validate={validateReason} name="reason" value={t("There_fire")}></FieldikRadio>
              <FieldikRadio validate={validateReason} name="reason" value={t("help")}></FieldikRadio>
            </Stack>
            <Fieldik name="remark" type="input" label={t("more_details")} variant="flushed"></Fieldik>
            <Flex mt={2} justifyContent="end">
                <Button type='submit' colorScheme='teal' mr={3} isLoading={props.isSubmitting} disabled={!(props.isValid && props.dirty)}>
                  {t("submit")}
                </Button>
                <Button colorScheme="gray" onClick={() => cancelClickCallback()}>
                {t("cancel")}
                </Button>
            </Flex>
          </Container>
        </Form>
        )}
      </Formik>}
    </ModalBase>
    )
}

export default AcknowledgeAlarmForm;