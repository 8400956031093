import { useTranslation } from 'react-i18next';

export const useErrorMessages = () => {
    const { t } = useTranslation();

    return Object.freeze({
        ERROR_PROJECT_ID_EMPTY: t('please_input_project_code'),
        ERROR_PROJECT_NAME_EMPTY: t('please_input_project_name'),
        ERROR_INSPECTOR_EMPTY: t('please_input_inspector'),
        ERROR_REASON_EMPTY: t('please_input_reason'),
        ERROR_USER_CODE_EMPTY: t('please_input_id_code'),
        ERROR_USER_NAME_EMPTY: t('please_input_name'),
        ERROR_USER_USERNAME_EMPTY: t('please_input_username'),
        ERROR_USER_USERNAME_THAI: t('username_not_support_th'),
        ERROR_USER_ROLE_EMPTY: t('please_input_role_type'),
        PASSWORD_THAI: t('password_not_support_th'),
        PASSWORD_MISMATCH: t('password_mismatch'),
        PASSWORD_INVALID: t('password_invalid'),
        PASSWORD_TOO_SHORT: t('password_too_short'),
        ERROR_MACADDRESS_EMPTY: t('please_input_mac_address'),
        ERROR_SERIAL_BOARD_EMPTY: t('please_input_serial_board'),
        ERROR_ADDRESS_EMPTY: t('please_input_address'),
        ERROR_MACADDRESS_NOT_VALID: t('mac_address_more'),
        ERROR_MACADDRESS_THAI: t('mac_address_not_support_th')
    });
};
