/* eslint-disable no-undef */
import React from "react";
import {
  Box,
  Heading,
  Container,
  Flex,
  Input,
  Text,
  Button,
  Divider,
  Image,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import { Formik, Form, Field } from 'formik';
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from 'react-router-dom'
import { FieldikPassword, Fieldik } from '../components/form/Fieldik'
import * as util from '../util';
import * as validator from './validator/validator'
import { useTranslation } from "react-i18next";

const CreateProject = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const bg = useColorModeValue("white", "gray.700");
  const color = useColorModeValue("gray.800", "white");
  const border = useColorModeValue("1px solid #E2E8F0", "");
  const [masterPlanImage, setMasterPlanImage] = React.useState(null);
  const [logoImage, setLogoImage] = React.useState(null);
  const auth = React.useContext(AuthContext);
  const [password, setPassword] = React.useState('');
  const { t } = useTranslation();
  const {
    validateProjectId,
    validateProjectName,
    validateUserCode,
    validateName,
    validateUserName,
    validatePassword,
    validateVPassword
  } = validator.useValidation();

  function redirectToLogin() {
    console.log('redirect to login state /dashboard/');
    if (auth.user.role === 'user_management') {
      navigate('/Login', { state: { path: "/dashboard/" } });
    }
    else {
      navigate('/rolehome');
    }
  }

  //Get Master plan image from user and convert to Base64
  async function handleGetTheMasterPlanImage(e) {
    try {
      const file = util.verifyFileSize(e.target.files);
      if (file) {
        const base64 = await util.loadFileToBase64(file);
        setMasterPlanImage(base64.split(",")[1]);
      }
      else {
        toast({ position: 'top-center', title: t("size_over"), status: 'error', duration: 5000, isClosable: true, });
      }
    }
    catch (err) {
      toast({ position: 'top-center', title: t("image_upload_failed"), description: err, status: 'error', duration: 5000, isClosable: true, });
    }
  }

  async function handleGetTheLogoImage(e) {
    try {
      const file = util.verifyFileSize(e.target.files);
      if (file) {
        const base64 = await util.loadFileToBase64(file);
        setLogoImage(base64.split(",")[1]);
      }
      else {
        toast({ position: 'top-center', title: t("size_over"), status: 'error', duration: 5000, isClosable: true, });
      }
    }
    catch (err) {
      toast({ position: 'top-center', title: t("image_upload_failed"), description: err, status: 'error', duration: 5000, isClosable: true, });
    }
  }

  async function handleSubmit(values, actions) {
    const newProject = {
      projectId: values.projectId,
      projectName: values.projectName,
      image: masterPlanImage,
      logo: logoImage,
      user: {
        username: values.username,
        password: values.password,
        code: values.userCode,
        name: values.name,
        role: "niti",
      },
    };

    try {
      const res = await axios.post(process.env.REACT_APP_BASE_URL + "/projects", newProject, { withCredentials: true })
      if (res.status == 200) {
        if (masterPlanImage == null || logoImage == null) {
          toast({ position: 'top-center', title: t("create_project_failed"), description: t("notfound_image_logo"), status: 'warning', duration: 5000, isClosable: true, });
        }
        else {
          toast({ position: 'top-center', title: t("create_project_success"), status: 'success', duration: 5000, isClosable: true, });
        }


        navigate('/dashboard');
      }
    }
    catch (err) {
      console.error(err);
      if (err.response) {
        if (err.response?.status === 401) {
          redirectToLogin();
        }
        else if (err.response?.status === 400) {
          toast({ position: 'top-center', title: t("create_project_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
        }
        else {
          toast({ position: 'top-center', title: t("create_project_failed"), status: 'error', duration: 5000, isClosable: true, });
        }
      }
    }
    actions.setSubmitting(false);
  }

  return (
    <Container
      h="auto"
      maxW="640px"
      mt="40px"
      p={7}
      borderRadius="md"
      border={border}
      color={color}
      bg={bg}
      boxShadow="xl"
    >
      <Heading mb={1} fontSize="36px">
        {t("create_project")}
      </Heading>
      <Text fontSize="20px" mb={10}>
        {t("project_info")}
      </Text>

      <Formik initialValues={{ projectId: '', projectName: '', userCode: '', name: '', username: '', password: '', vpassword: '' }} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Flex justify="center">
              <Box w="50%" mr={5}>
                <Fieldik name="projectId" validate={validateProjectId} type="input" label={t("project_code")} variant="flushed"></Fieldik>
              </Box>
              <Box w="50%">
                <Fieldik name="projectName" validate={validateProjectName} type="input" label={t("project_name")} variant="flushed"></Fieldik>
              </Box>
            </Flex>

            <Flex justify="space-between">
              <Flex mt={10} flexDir="column" w="50%">
                <Box w="100%" mb={5}>
                  <Text color="red" mb={3} fontSize="16px">
                    {t("size_not_over")}
                  </Text>
                  <Text mb={3} fontSize="16px">
                    {t("project_image")} (Masterplan)
                  </Text>
                  {!masterPlanImage ? (
                    <Box></Box>
                  ) : (
                    <Image
                      my={2}
                      src={`data:image/png;base64,${masterPlanImage}`}
                      h="100px"
                    />
                  )}
                  <label className="button" htmlFor="upload-image">{t("select_image")}</label>
                  <Input opacity="0" id="upload-image" type="file" accept=".png,.jpeg,.jpg" onChange={handleGetTheMasterPlanImage}></Input>
                </Box>
              </Flex>
              <Flex mt={10} flexDir="column" w="50%">
                <Box w="100%">
                  <Text color="red" mb={3} fontSize="16px">
                    {t("size_not_over")}
                  </Text>
                  <Text mb={3} fontSize="16px">
                    {t("project_logo")}
                  </Text>
                  {!logoImage ? (
                    <Box></Box>
                  ) : (
                    <Image
                      my={2}
                      src={`data:image/png;base64,${logoImage}`}
                      h="100px"
                    />
                  )}
                  <label className="button" htmlFor="upload-logo">{t("select_logo")}</label>
                  <Input opacity="0" id="upload-logo" type="file" accept=".png,.jpeg,.jpg" onChange={handleGetTheLogoImage}></Input>
                </Box>
              </Flex>
            </Flex>
            <Divider my={1} />

            <Text fontSize="20px" mb={5}>
              {t("create_niti_account")}
            </Text>
            <Flex justify="center">
              <Box w="50%" mr={5}>
                <Fieldik name="userCode" validate={validateUserCode} type="input" label={t("id_code")} variant="flushed"></Fieldik>
              </Box>
              <Box w="50%">
                <Fieldik name="name" validate={validateName} type="input" label={t("name_surname")} variant="flushed"></Fieldik>
              </Box>
            </Flex>

            <Box w="48%" mr={5} my={3}>
              <Fieldik name="username" validate={validateUserName} type="input" label={t("username")} variant="flushed"></Fieldik>
            </Box>

            <Flex justify="center" my={3}>
              <Box w="50%" mr={5}>
                <FieldikPassword name={'password'} label={t("new_password")} enableShowButton={true} validate={(value) => {
                  validatePassword(value, password);
                  setPassword(value);
                }} ></FieldikPassword>
              </Box>
              <Box w="50%">
                <FieldikPassword name={'vpassword'} validate={(value) => validateVPassword(value, password)} label={t("confirm_password")}></FieldikPassword>
              </Box>
            </Flex>
            <Flex justifyContent="end">
              <Button mt={1} type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}
                disabled={!(props.isValid && props.dirty)}
              >
                {t("submit")}</Button>
            </Flex>

          </Form>)}
      </Formik>
    </Container>
  );
};

export default CreateProject;

