const FILE_SIZE_LIMIT = 2097152;

export async function loadFileToBase64(fileData) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileData);

    return new Promise((resolve,reject) => {
        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = () => {
            reject("error");
        };
    })
}

export function verifyFileSize(files) {
    if(files.length > 0 && files[0].size < FILE_SIZE_LIMIT){
        return files[0];
    }
    return null;
}

export function guid() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}