import React from "react";
import {
  chakra,
  Divider,
  Center,
  IconButton,
  Tooltip,
  Flex,
  Box,
  Button,
  Container,
  Image,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Input,
  useToast,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { BsKey } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { MdOutlineAddLocationAlt, MdOutlineLogout } from "react-icons/md";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import ModalPasswordChange from "../components/modal/ModalPasswordChange";
import ModalForm from "../components/modal/ModalForm";
import { Fieldik } from "../components/form/Fieldik";
import * as validator from "./validator/validator";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const ActionButtons = ({
  cell,
  projects,
  onDelete,
  onEdit,
  onAssign,
  user_name,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [user, setUser] = React.useState({});
  const [project, setProject] = React.useState({});
  const auth = React.useContext(AuthContext);
  const { t } = useTranslation();
  const {
    validateProjectId,
    validateUserCode,
    validateName
  } = validator.useValidation();

  let propsik = null;

  //OpenModule
  //Logout
  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();
  //Change Password
  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure();
  //Edit
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();
  //Delete
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  //Handle Logout Click
  const handleLogoutClick = async () => {
    setUser(cell);
    onLogoutOpen();
  };
  //Handle Change password Click
  const handleChangePasswordClick = () => {
    setUser(cell);
    onChangePasswordOpen();
  };
  //Handle Edit Click
  const handleEditClick = () => {
    setUser(cell);
    onEditOpen();
  };

  //Handle Assgin Click
  const handleAssignClick = () => {
    setUser(cell);
    onAssignOpen();
  };

  //Handle Delete User Click
  const handleDeleteClick = () => {
    setUser(cell);
    onDeleteOpen();
  };

  async function logoutAsync() {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "/force_logout/",
        user,
        { withCredentials: true }
      );
    } catch (err) {
      if (err.response?.status == 401) {
        redirectToLogin();
      }
      console.error(err);
    }
  }

  function handleProjectSelect(e) {
    if (e.target.value) {
      const project = projects.find((p) => {
        return p._id == e.target.value;
      });
      setProject(project);
    } else {
      setProject(null);
    }
  }

  function redirectToLogin() {
    console.log("redirect to login state /usermanagement/");
    if (auth.user.role == "user_management") {
      navigate("/Login", { state: { path: "/usermanagement/" } });
    } else {
      navigate("/rolehome");
    }
  }

  //handle Force Logout submit
  async function handleLogoutSubmit() {
    await logoutAsync();
    onLogoutClose();
  }
  //handle Change password submit
  async function handlePasswordSubmit(values, actions) {
    const newPassword = { password: values.password };
    try {
      const updateResponse = await axios.put(
        process.env.REACT_APP_BASE_URL +
        "/users/" +
        user.username +
        "/resetpassword",
        newPassword,
        { withCredentials: true }
      );
      if (updateResponse.status == 200) {
        toast({
          position: "top-center",
          title: t("change_password_success"),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        actions.setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      if (err.response) {
        if (err.response?.status == 401) {
          redirectToLogin();
        } else {
          toast({
            position: "top-center",
            title: t("change_password_failed"),
            description: err.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
    onChangePasswordClose();
  }
  // handle edit submit
  async function handleEditSubmit(values, actions) {
    const editUser = {
      name: values.name,
      code: values.code,
    };
    const result = await onEdit(user.username, editUser);
    if (result) {
      onEditClose();
      actions.setSubmitting(false);
    }
  }

  async function handleAssignSubmit(values, actions) {
    const result = await onAssign(values.projectId, user);
    if (result) {
      onAssignClose();
      actions.setSubmitting(false);
    }
  }

  //handle Delete user submit
  async function handleDeleteSubmit() {
    onDelete(user);
    onDeleteClose();
  }

  return (
    <>
      {/* Action Icon */}
      <Flex mx={2} align="center" justify={"space-between"}>
        {(auth.user.role == "user_management" || auth.user.role == "admin") && (
          <Tooltip label={t("logout")}>
            <IconButton
              onClick={() => handleLogoutClick()}
              bg=""
              aria-label="Logout"
              icon={<MdOutlineLogout />}
            />
          </Tooltip>
        )}
        {(auth.user.role == "user_management" || auth.user.role == "niti") && (
          <Tooltip label={t("change_password")}>
            <IconButton
              onClick={() => handleChangePasswordClick()}
              bg=""
              aria-label="Change password"
              icon={<BsKey />}
            />
          </Tooltip>
        )}
        {(auth.user.role == "user_management" || auth.user.role == "niti") && (
          <Tooltip label={t("edit_user")}>
            <IconButton
              onClick={() => handleEditClick()}
              bg=""
              aria-label="Edit user"
              icon={<FaRegEdit />}
            />
          </Tooltip>
        )}
        {auth.user.role == "user_management" && (
          <Tooltip label={t("assign_project")}>
            <IconButton
              onClick={() => handleAssignClick()}
              bg=""
              aria-label="Assigned project"
              icon={<MdOutlineAddLocationAlt />}
            />
          </Tooltip>
        )}
        {(auth.user.role == "user_management" || auth.user.role == "niti") && (
          <Center height="30px">
            <Divider orientation="vertical" />
          </Center>
        )}
        {(auth.user.role == "user_management" || auth.user.role == "niti") && (
          <Tooltip label={t("delete_user")}>
            <IconButton
              onClick={() => handleDeleteClick()}
              bg=""
              aria-label="Delete user"
              icon={<MdDeleteForever />}
            />
          </Tooltip>
        )}
      </Flex>

      {/* Module */}

      {/* Force Logout */}
      <Modal onClose={onLogoutClose} isOpen={isLogoutOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="24px">{t("force_logout")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container maxW="2xl">
              <Flex align="center">
                <Heading mr={5} fontSize="18px">
                  {t("do_you_want")} "{user.name}" {t("logout_system")}
                </Heading>
              </Flex>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mx={3} onClick={handleLogoutSubmit}>
              {t("submit")}
            </Button>
            <Button colorScheme="red" onClick={onLogoutClose}>
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalPasswordChange
        isCentered
        header={t("change_user_password") + " : " + user.username}
        isOpen={isChangePasswordOpen}
        onClose={onChangePasswordClose}
        onSubmit={(values, actions) => handlePasswordSubmit(values, actions)}
      ></ModalPasswordChange>

      <ModalForm
        isCentered
        header={
          <>
            <chakra.span color="yellow.500">{t("edit")}</chakra.span>{" "}
            {t("niti_info")}
          </>
        }
        isOpen={isEditOpen}
        onClose={onEditClose}
        onSubmit={(values, actions) => handleEditSubmit(values, actions)}
        initialValues={{ name: user.name, code: user.code }}
      >
        <>
          <Fieldik
            name="name"
            validate={validateName}
            type="input"
            label={t("name_surname")}
            variant="flushed"
          ></Fieldik>
          <Fieldik
            name="code"
            validate={validateUserCode}
            type="input"
            label={t("employee_id")}
            variant="flushed"
          ></Fieldik>
        </>
      </ModalForm>

      {auth.user.role == "user_management" && (
        <ModalForm
          isCentered
          header={
            <>
              <chakra.span color="yellow.500">{t("assign")}</chakra.span>{" "}
              {t("project")} : {user.username}
            </>
          }
          isOpen={isAssignOpen}
          onClose={onAssignClose}
          onSubmit={(values, actions) => handleAssignSubmit(values, actions)}
          initialValues={{ projectId: "" }}
          setPropIK={(e) => (propsik = e)}
        >
          <>
            {projects.length > 0 && (
              <Field name="projectId" validate={validateProjectId}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.projectId}>
                    <Select
                      m={2}
                      onChange={(e) => {
                        propsik.setFieldValue(
                          "projectId",
                          e.target.value,
                          true
                        );
                      }}
                    >
                      <option value="">{t("please_select_project")}</option>
                      {projects.map((project) => (
                        <option key={project._id} value={project._id}>
                          {project.projectName}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
          </>
        </ModalForm>
      )}

      {/* Delete USER */}
      {user.username === user_name ? (
        <Modal onClose={onDeleteClose} isOpen={isDeleteOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="24px">{t("delete_user_account")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container maxW="2xl">
                <Flex align="center">
                  <Heading mr={5} fontSize="18px">
                    {("cannot_delete_user_account")}
                  </Heading>
                </Flex>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mx={3} onClick={onDeleteClose}>
                {t("submit")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Modal onClose={onDeleteClose} isOpen={isDeleteOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="24px">{t("delete_user_account")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container maxW="2xl">
                <Flex align="center">
                  <Heading mr={5} fontSize="18px">
                    {t("cf_delete_user_account")} "{user.name}" {t("or_not")}
                  </Heading>
                </Flex>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mx={3} onClick={handleDeleteSubmit}>
                {t("submit")}
              </Button>
              <Button colorScheme="red" onClick={onDeleteClose}>
                {t("cancel")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ActionButtons;
