import React from "react";
import{Flex, Container, Heading} from "@chakra-ui/react";
import Header from "../components/Header";
import Navigatebar from "../components/Navigatebar";
import HardwareStatus from "../components/HardwareStatus"
import { useTranslation } from "react-i18next";

const Hardwarestatus =()=>{
    const { t } = useTranslation();
    return(
        <>
            <Header />
            <Navigatebar page={t("hardware_status")} />
            <Container maxW="1200px" mt={10}>
                <Heading fontSize="36">{t("hardware_status")}</Heading>
                <Flex justify="center" w="auto">
                    <HardwareStatus />
                </Flex>
            </Container>
        </>
    )
}

export default Hardwarestatus