import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Image,
  Box,
  Wrap,
  WrapItem,
  useColorModeValue,
  Flex,
  Input,
} from "@chakra-ui/react";
import placeholder from '../images/placeholder-project.png';
import Search from "../components/Search";
import { AuthContext } from "../contexts/AuthContext";

const ProjectLists = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [projects, setProjects] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const auth = React.useContext(AuthContext);
  // const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/projects', {withCredentials:true});
        setProjects(res.data);
      }
      catch(err){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        console.log(err);
      }
    }
    fetchData();
  }, [auth]);

  function redirectToLogin(){
    console.log('redirect to login state /dashboard/');
    if(auth.user.role == 'user_management'){
      navigate('/Login', {state : { path: "/dashboard/"}});
    } 
    else{
      navigate('/roleHome');
    }
  }

  const handleEditProject = (_id) => {
    navigate('/editproject/' + _id + location.search, { _id });
    // console.log(projectId);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchedProject);
  };

  const searchedProject = projects.filter((project) =>
    project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Flex flexDir="column">
        <Search onSearch={handleSearch} />

        <Box mt={10}>
          <Wrap spacing="30px" justifyContent="center">
            <Project projects={searchedProject} onClick={(_id) => handleEditProject(_id)} />
          </Wrap>
        </Box>
      </Flex>
    </>
  );
};

const Project = (props) => {
  const bg = useColorModeValue("white", "gray.700");
  const color = useColorModeValue("gray.800", "white");
  const border = useColorModeValue("1px solid", "");
  return (
    <>
      {props.projects.map((item) => {
        return (
          <WrapItem key={item._id}>
            <Box
              w="300px"
              h="300px"
              bg={bg}
              border={border}
              borderColor="gray.300"
              borderRadius="10px"
              overflow="hidden"
              _hover={{ boxShadow: "xl", transform: "scale(1.03)" }}
              onClick={() => props.onClick(item._id)}
            >
              <Box h="170px" overflow="hidden">
                {item.image?
                <Image src={`data:image/png;base64,${item.image}`} alt="Dan Abramov"/>:
                <Image src={placeholder} alt={item.name} />
                }
              </Box>
              <Box p={6} overflow="hidden">
                <Flex align="center">
                  <Box mr="2px" boxSize={7}>
                      {item.logo?
                    <Image className="logo" src={`data:image/png;base64,${item.logo}`} alt="Dan Abramov"/>:
                    <Image className="logo" src={placeholder} alt={item.name} />
                    }
                  </Box>
                  <Box color={color} fontSize="xl" >
                    <span display="inline-block" vertical-align="middle">{item.projectName}</span>
                  </Box>
                </Flex>
                <Box color={color} fontSize="md">
                  {item.projectId}
                </Box>
              </Box>
            </Box>
          </WrapItem>
        );
      })}
    </>
  );
};

export default ProjectLists;
