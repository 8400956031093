import React from "react";
import Header from "../components/Header";
import { Box } from "@chakra-ui/react";
import EditProject from "../components/EditProject";
import Navigatebar from "../components/Navigatebar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditProjectPage = () => {

  const {_id} = useParams();
  const { t } = useTranslation();

  return (
    <Box>
      <Header />
      <Navigatebar page={t("project")} />
      <EditProject _id={_id} />
    </Box>
  );
};

export default EditProjectPage;
