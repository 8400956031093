import React from "react";
import Header from "../components/Header";
import ProjectTable from "../components/ProjectTable";
import Navigatebar from "../components/Navigatebar";
import { Flex, Heading, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ProjectManagement = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Navigatebar page={t("manege_project")} />
      <Container maxW="1200px" mt={10}>
        <Heading fontSize="36">{t("manege_project")}</Heading>
        <Flex justify="center" w="auto">
          <ProjectTable />
        </Flex>
      </Container>
    </>
  );
};

export default ProjectManagement;
