import React from "react";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const Search = (props) => {
  return (
    <Box mx="auto" w="600px" my="20px">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input id="search" onChange={props.onSearch} placeholder="Search" />
      </InputGroup>
    </Box>
  );
};

export default Search;
