/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import {
  Box,
  Button, Flex, Image, Input,
  InputGroup,
  InputRightElement,
  Stack, useToast
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
const property = {
  imageUrl: "https://www.aheadall.co.th/media/img/2021/04/AA-LOGO-Glow.png",
  imageAlt: "AheadAll",
};


const Login = ({ onLogin: onLoginCallBack }) => {
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const handlePasswordShowHideClick = () => setShow(!show);

  const location = useLocation();
  const navigate = useNavigate();



  React.useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  function keyDownHandler(event) {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  }

  async function handleLoginClick() {
    const user = { username: username, password: password };
    try {
      const result = await axios.post(process.env.REACT_APP_BASE_URL + '/login', user, { withCredentials: true });
      if (result.status == 200) {
        const authContext = result.data;
        onLoginCallBack(authContext);
        storeAuth(authContext);
        navigateByRole(authContext);
      }
    }
    catch (err) {
      if (err.response?.status) {
        console.log(err);
        toast({ position: 'top-center', title: `${err.response.data.message}`, status: 'error', duration: 5000, isClosable: true, });
      }
      else {
        toast({ position: 'top-center', title: `${err}`, status: 'error', duration: 5000, isClosable: true, });
      }
    }
  }

  function navigateByRole(authContext) {
    if (location.state?.path != null) {
      console.log('redirect to ' + location.state?.path);
      navigate(location.state?.path);
    }
    else {
      switch (authContext.user.role) {
        case "admin":
          // navigate("/dashboard" + location.search);
          navigate("/selectposition/");
          break;
        case "security":
          navigate("/alarmdetectionboard/" + location.search);
          break;
        case "niti":
          navigate("/alarmdetectionboard/" + location.search);
          break;
        case "user_management":
          navigate("/usermanagement/" + location.search);
          break;
        case "lift":
          navigate("/liftmonitor/" + location.search);
          break;
        default:
          break;
      }
    }
  }

  function storeAuth(loginContext) {
    localStorage.setItem('authContext', JSON.stringify(loginContext));
  }

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  return (
    <>
      <Flex align="center" justify="center">
        <Box
          h="600px"
          w="600px"
          bgColor="#FFFFF"
          border="1px"
          borderColor="#ababab"
          borderRadius="13px"
          mt="20"
          p="10"
        >
          <Flex align="center" justify="center" flexDir="column">
            <Image
              h="100px"
              mb="20"
              src={property.imageUrl}
              alt={property.imageAlt}
            />
            <Stack spacing={4}>
              <Input pr="4.5rem" type="text" placeholder="Enter username" onChange={handleUsernameChange} />
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  onChange={handlePasswordChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handlePasswordShowHideClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Button
                onClick={async () => await handleLoginClick()}
              >
                Login
              </Button>

            </Stack>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Login;



Login.propTypes = {
  onLogin: PropTypes.func,
}