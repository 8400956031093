import React from 'react'
import {chakra,Divider,Center,IconButton,Tooltip,Flex,Box, Button, Container, Image, Heading, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, 
    Select, ModalCloseButton, useDisclosure, FormLabel, Input, useToast} from '@chakra-ui/react'
import {BsFillPeopleFill} from "react-icons/bs"
import {FaRegEdit} from "react-icons/fa"
import {MdDeleteForever} from "react-icons/md"
import {MdOutlineAddLocationAlt,MdOutlineLogout} from "react-icons/md"
import axios from 'axios'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import ViewUser from './project/ViewUser'
import { useTranslation } from "react-i18next";

const ProjectActionButtons = ({rowProject, onAction, projects})=>{
    const navigate = useNavigate();
    const toast = useToast();
    const [user,setUser] = React.useState({})
    const [name,setName] = React.useState("")
    const [userId,setUserId] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordConfirm, setPasswordConfrim] = React.useState("")
    const [project,setProject] = React.useState({})
    const [users,setUsers] = React.useState([])
    const auth = React.useContext(AuthContext);
    const { t } = useTranslation();

//OpenModule
      //Edit
      const {
        isOpen: isEditOpen,
        onOpen: onEditOpen,
        onClose: onEditClose,
      } = useDisclosure();  
      const {
        isOpen: isAssignOpen,
        onOpen: onAssignOpen,
        onClose: onAssignClose,
      } = useDisclosure();  
      //View
      const {
      isOpen: isViewOpen,
      onOpen: onViewOpen,
      onClose: onViewClose,
    } = useDisclosure();  
       //Delete
       const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
      } = useDisclosure();  

    //Handle Edit Click
      const handleEditClick = ()=>{
        setUser(rowProject)
        setName(rowProject.name)
        setUserId(rowProject.code)
        console.log(user);
        onEditOpen()
    }

      //Handle Assgin Click
      const handleAssignClick = ()=>{
        setUser(rowProject)
        setName(rowProject.name)
        setUserId(rowProject.code)
        console.log(user);
        onAssignOpen();
    }
    

    const handleViewUserClick = ()=>{
      console.log(rowProject.users);
      setUsers(rowProject.users);
      onViewOpen();
    }

    //Handle Delete User Click
    const handleDeleteClick = ()=>{
        setUser(rowProject)
        console.log(user);
        onDeleteOpen()
    
        }

    function handleProjectSelect(e){
      if(e.target.value){
        const project = projects.find(p => { return p._id == e.target.value});
        setProject(project);
      }
      else{
        setProject(null);
      }
    };

    function redirectToLogin(){
        console.log('redirect to login state /usermanagement/');
        if(auth.user.role == 'user_management'){
          navigate('/Login', {state : { path: "/usermanagement/"}});
        } 
        else{
          navigate('/rolehome');
        }
      }
      // handle edit submit
    async function handleEditSubmit(){
        const editUser = { name,code: userId };
        console.log(editUser);
        try {
          const updateResponse = await axios.put(process.env.REACT_APP_BASE_URL + "/users/" + user.username, editUser,{withCredentials: true});
          if(updateResponse.status == 200){
            toast({ position: 'top-center', title: t("edit_data_success"), status: 'success', duration: 5000, isClosable: true,});
            onAction('edit');
          }
        }
        catch (err){
          console.log(err);
          if(err.response){
            if(err.response?.status == 401){
              redirectToLogin();
            }
            else{
              toast({ position: 'top-center', title: t("edit_data_failed"), description:err.response.data.message, status: 'error', duration: 5000, isClosable: true,});
            }
          }
        }
        onEditClose();
      };

      async function handleAssignSubmit(){
        console.log(project);
        try {
          const updateResponse = await axios.post(process.env.REACT_APP_BASE_URL + "/projects/" + project._id + "/assign_project", user,{withCredentials: true});
          if(updateResponse.status == 200){
            toast({ position: 'top-center', title: t("assign_project_success"), status: 'success', duration: 5000, isClosable: true,});
            onAction('edit');
          }
        }
        catch (err){
          console.log(err);
          toast({ position: 'top-center', title: t("assign_project_failed"), status: 'error', duration: 5000, isClosable: true,});
          if(err.response?.status == 401){
            redirectToLogin();
          }
        }
        onAssignClose();
      };

       //handle Delete user submit
       async function handleDeleteSubmit(){
      
        try {
          if(user.username == auth.user?.username){
            toast({ position: 'top-center', title: t("cannot_delete_project"), status: 'error', duration: 5000, isClosable: true,});
            return;
          }
          const updateResponse = await axios.delete(process.env.REACT_APP_BASE_URL + "/projects/" + rowProject._id,{withCredentials: true});
          if(updateResponse.status == 200){
            toast({ position: 'top-center', title: t("delete_project_success"), status: 'success', duration: 5000, isClosable: true,});
            onAction('delete');
          }
        }
        catch (err){
          console.log(err);
          if(err.response){
            if(err.response?.status == 401){
              redirectToLogin();
            }
            else{
              toast({ position: 'top-center', title: t("delete_project_failed"), status: 'error', duration: 5000, isClosable: true,});
            }
          }
        }
        onDeleteClose();
      };
    return(
        <>
        {/* Action Icon */}
        <Flex w = "130px" mx = {2} align = "center" justify={'space-between'}>
            {(auth.user.role == 'user_management' || auth.user.role == 'niti') && <Tooltip label={t("edit_project")}>
            <IconButton onClick ={()=>handleEditClick()}bg = "" aria-label='Edit project' icon={<FaRegEdit />} />
            </Tooltip>}
            {auth.user.role == 'user_management' && <Tooltip label={t("assigned_project")}>
            <IconButton onClick = {()=>handleAssignClick()} bg = "" aria-label='Assigned project' icon={<MdOutlineAddLocationAlt />} />
            </Tooltip>}
            {auth.user.role == 'user_management' && <Tooltip label={t("view_users")}>
            <IconButton onClick ={()=>handleViewUserClick()} bg = "" aria-label='View user' icon={<BsFillPeopleFill />} />
            </Tooltip>}
            <Center height='30px'>
                <Divider orientation='vertical' />
            </Center>
            {(auth.user.role == 'user_management' || auth.user.role == 'niti') && <Tooltip label={t("delete_project")}>
            <IconButton onClick ={()=>handleDeleteClick()} bg = "" aria-label='Delete project' icon={<MdDeleteForever />} />
            </Tooltip>}
        </Flex>
        
        {/* Module */}

      {/* Edit USER */}
      <Modal onClose={onEditClose} isOpen={isEditOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="24px">
            {" "}
            <chakra.span color="yellow.500">{t("edit")}</chakra.span> {t("niti_info")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container maxW="2xl">
              <Flex align="center">
                <Heading mr={5} fontSize="18px">
                {t("username")} : {user.username}
                </Heading>
              </Flex>
             
              <Flex h="200px" justify="space-around" flexDir="column" mt={5}>
                <Box w="100%">
                  <FormLabel fontSize="16px" m={0}>
                  {t("name_surname")} {t("employee")}
                  </FormLabel>
                  <Input
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder={t("please_input_emp_name")}
                    variant="flushed"
                  />
                </Box>
                <Box w="100%">
                  <FormLabel fontSize="16px" m={0}>
                  {t("employee_id")}
                  </FormLabel>
                  <Input
                    id=""
                    value={userId}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                    placeholder={t("please_input_emp_id")}
                    variant="flushed"
                  />
                </Box>
               
              </Flex>
            </Container>
          </ModalBody>
          <ModalFooter>
           
            <Button colorScheme="teal" mx={3} onClick={handleEditSubmit}>
              {t("submit")}
            </Button>
            <Button colorScheme="red" onClick={onEditClose}>
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      {/* Assign USER */}
      {auth.user.role == 'user_management' && <Modal onClose={onAssignClose} isOpen={isAssignOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="24px">
            {" "}
            <chakra.span color="yellow.500">{t("assign")}</chakra.span> {t("assign_project_to_emp")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container maxW="2xl">
              <Flex align="center">
                <Heading mr={5} fontSize="18px">
                {t("username")} : {user.username}
                </Heading>
              </Flex>
             
              <Flex justify="space-around" flexDir="column" mt={5}>
                <Box w="100%">
                <Select m={2} placeholder={t("please_select_project")} onChange={handleProjectSelect}>
                {projects.map((project) => (
                  <option key={project._id} value={project._id}>{project.projectName}</option>
                ))}
              </Select>
                </Box>
              </Flex>
            </Container>
          </ModalBody>
          <ModalFooter>
           
            <Button colorScheme="teal" mx={3} onClick={handleAssignSubmit}>
              {t("submit")}
            </Button>
            <Button colorScheme="red" onClick={onAssignClose}>
            {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>}
      {/* Delete USER */}
      <Modal onClose={onDeleteClose} isOpen={isDeleteOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="24px">
          {t("delete_project")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container maxW="2xl">
              <Flex align="center">
                <Heading mr={5} fontSize="18px">
                {t("cf_delete_project")} "{rowProject.projectName}" {t("or_not")}
                </Heading>
              </Flex>

            </Container>
          </ModalBody>
          <ModalFooter>
           
            <Button colorScheme="teal" mx={3} onClick={handleDeleteSubmit}>
              {t("submit")}
            </Button>
            <Button colorScheme="red" onClick={onDeleteClose}>
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ViewUser users={users} isOpen={isViewOpen} onClose={onViewClose} onOpen={onViewOpen}/>
        </>
    )
}

export default ProjectActionButtons;