import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton
  } from '@chakra-ui/react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const ModalBase = (props) => {

    return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered={props.isCentered || false}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                {props.children}
            </ModalBody>
        </ModalContent>
    </Modal>
    )
}

export default ModalBase;

ModalBase.propTypes = {
    children:PropTypes.node,
    header:PropTypes.any,
    isOpen:PropTypes.bool,
    onClose:PropTypes.func,
    isCentered:PropTypes.bool,
}
