import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  chakra,
  Icon,
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Badge,
  Avatar,
  IconButton,
  Select,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import { BsClockHistory, BsCalendarDate } from "react-icons/bs";
import { BiUserCircle, BiCommentDetail } from "react-icons/bi";
import { AiFillTag } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { useTable, usePagination, useSortBy } from "react-table";
import axios from "axios";
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import ModalBase from '../modal/ModalBase';
import { useTranslation } from "react-i18next";

function AlarmRecordTable() {
  const auth = React.useContext(AuthContext);
  const [records,setRecords] = React.useState([]);
  const navigate = useNavigate();
  const [remark,setRemark] = React.useState('');
  const {isOpen:isRemarkOpen,onOpen:onRemarkOpen,onClose:onRemarkClose} = useDisclosure();
  const { t } = useTranslation();

  function createDisplayData(history) {
    let records = []
    let counter = history.length;
    history.forEach(alarm => {
      const data = {
        no: counter,
        address: alarm.address,
        date: formatDate(alarm.date),
        start: alarm.notify_start_time,
        stop: alarm.notify_stop_time,
        reason : formatReason(alarm),
        station_user : alarm.record?.user.username,
        inspector : alarm.record?.inspector?.username,
        remark: alarm.record?.remark,
      };
      counter--;
      records.push(data);
    });
    records.reverse();
    setRecords([...records]);
  }

  function formatDate(date) {
    return date.replace(/T.*/,'').split('-').reverse().join('-');
  }

  function formatReason(alarm) {
    if(alarm.alarm_type.identifier == 0){
      return "CLEAR";
    }
    else if(alarm.record?.reason){
      return alarm.record?.reason;
    }
    return t("reason_not_specified");
  }
  

  React.useEffect(() => {
    if(!auth.user.username) return;
    const fetchAlarmRecord = async () => {
      try{
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/alarms/' + auth.project._id ,{withCredentials: true});
        if(Array.isArray(res.data)){
          res.data.reverse();
        }
        createDisplayData(res.data);
      } 
      catch(err) {
        if(err.response?.status == 401){
          redirectToLogin();
        }
        console.error(err);
      }
    }
    fetchAlarmRecord();
  },[auth.user]);

  function redirectToLogin(){
    console.error('redirect to login state /alarmrecord/');
    if(auth.user.role == 'security' || auth.user.role == 'niti'){
      navigate('/Login', {state : { path: "/alarmrecord/"}});
    } 
    else{
      navigate('/rolehome');
    }
  }

  const bgHead = useColorModeValue("gray.200", "gray.700");
  const bgBody = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const border = useColorModeValue("1px solid #E2E8F0", "");
  const data = React.useMemo(
    () => records,
    [records]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "no",
        width: 300
      },
      {
        Header: t("address"),
        accessor: "address",
      },
      {
        Header: t("date"),
        accessor: "date",
      },
      {
        Header: t("time_alert"),
        accessor: "start",
      },
      {
        Header: t("time_stop"),
        accessor: "stop",
      },
      {
        Header: t("reason"),
        accessor: "reason",
      },
      {
        Header: t("niti_station"),
        accessor: "station_user",
      },
      {
        Header: t("inspector"),
        accessor: "inspector",
      },
      {
        Header: t("remark"),
        accessor: "remark",
      },
    ],
    [records, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: false, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      //pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2
            )}
          </code>
        </pre>
     */}
      <Table
        className="table-tiny"
        mt={10}
        w="1200px"
        border={border}
        borderColor="gray.200"
        borderRadius="10px"
        {...getTableProps()}
      >
        <Thead bg={bgHead} color={color}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                key={column.accessor}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  <Flex color={color} align="center" flexDir="row">
                    <chakra.span pr="1">
                      {column.id == "no" ? (
                        <></>
                      ) : column.id == "date" ? (
                        <Icon w={4} h={4} as={BsCalendarDate} />
                      ) : column.id == "timestamp" ? (
                        <Icon w={4} h={4} as={BsClockHistory} />
                      ) : column.id == "detail" ? (
                        <Icon w={4} h={4} as={BiCommentDetail} />
                      ) : (
                        <Icon
                          w={5}
                          h={5}
                          transform="translateY(3px)"
                          as={HiOutlineStatusOnline}
                        />
                      )}
                    </chakra.span>
                    {column.render("Header")}
                    <chakra.span pl="1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody bg={bgBody} color={color} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td key={cell.row.original._id} {...cell.getCellProps()}>
                    {cell.column.id == "no" ? (
                      <Box>{cell.render("Cell")}</Box>
                    ) : cell.column.id == "address" ? (
                      <Badge colorScheme="teal">{cell.render("Cell")}</Badge>
                    ) : cell.column.id == "date" ? (
                      <Badge colorScheme="blue">{cell.render("Cell")}</Badge>
                    ) : cell.column.id == "start" ? (
                      <Badge variant="outline" colorScheme="red">
                        {cell.render("Cell")}
                      </Badge>
                    ) : cell.column.id == "stop" ? (
                      <Badge variant="outline" colorScheme="green">
                        {cell.render("Cell")}
                      </Badge>
                    ) : cell.column.id == "reason" ? (
                      <Badge colorScheme="red">{cell.render("Cell")}</Badge>
                    ) : (
                      // <>{cell.render("Cell")}</>
                      <Text w={128} overflow="hidden" textOverflow="ellipsis" cursor="pointer" whiteSpace="nowrap" onClick={() => {setRemark(cell.value); onRemarkOpen();}} >{cell.render("Cell")}</Text>
                    )}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan="100000">
              <Flex justify="space-between" align="center">
                <Flex
                  w="230px"
                  justify="space-between"
                  align="center"
                  shrink="0"
                >
                  <Text>Show rows per page</Text>

                  <Select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                    placeholder=""
                    w="75px"
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex w="180px" justify="space-between" align="center">
                  <Text>{`Page ${pageIndex + 1} of ${pageCount}`}</Text>
                  <IconButton
                    aria-label="Previous Page"
                    bg={bgBody}
                    icon={<ChevronLeftIcon color={color} />}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <IconButton
                    aria-label="Next Page"
                    bg={bgBody}
                    color={color}
                    icon={<ChevronRightIcon />}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Flex>
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
      <ModalBase isOpen={isRemarkOpen} onClose={onRemarkClose} isCentered
      header={
        <chakra.span fontSize={24} color="teal">{t("remark")}</chakra.span>
      }
      >
        <Flex>
          <Text overflow="auto">{remark}</Text>
        </Flex>
      </ModalBase>
    </>
  );
}

export default AlarmRecordTable;
