import React from "react";
import { Flex, Text, Switch, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function ChangColorMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { t } = useTranslation();
  return (
    <Flex align="center">
      <Switch onChange={toggleColorMode}></Switch>
      <Text ml={2}> {colorMode === "light" ? t("dark_mode") : t("light_mode")}</Text>
    </Flex>
  );
}

export default ChangColorMode;
