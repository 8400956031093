import React from 'react';
import {
  Button,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import ModalBase from './ModalBase';
import { FieldikPassword } from './../form/Fieldik';
import PropTypes from 'prop-types';
import * as validator from '../validator/validator'
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
const ModalPasswordChange = ({ header, children, isOpen, onClose, onSubmit, isCentered }) => {
  const [password, setPassword] = React.useState('');
  const { t } = useTranslation();
  const {
    validatePassword,
    validateVPassword
  } = validator.useValidation();

  return (
    <ModalBase isCentered={isCentered} isOpen={isOpen} onClose={onClose} header={header || t("change_password")} >
      {children}
      <Formik initialValues={{ password: '', vpassword: '' }} onSubmit={onSubmit}>
        {(props) => (
          <Form>
            <FieldikPassword name={'password'} validate={(value) => {
              validatePassword(value, password);
              setPassword(value);
            }} label={t("new_password")} enableShowButton={true}></FieldikPassword>
            <FieldikPassword name={'vpassword'} validate={(value) => validateVPassword(value, password)} label={t("confirm_password")} ></FieldikPassword>
            <Button mt={4} type='submit' disabled={!(props.isValid && props.dirty)} colorScheme='blue' mr={3} isLoading={props.isSubmitting}>
              {t("submit")}
            </Button>
          </Form>
        )}
      </Formik>
    </ModalBase>
  )
}

export default ModalPasswordChange;

ModalPasswordChange.propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  children: PropTypes.element,
  isCentered: PropTypes.bool,
}
