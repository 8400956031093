import React from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./App.css";
import { AuthContext } from "./contexts/AuthContext";
import AccountManagement from "./Pages/accountmanagement";
import AlarmDetectionBoard from "./Pages/AlarmDetectionBoard";
import AlarmRecord from "./Pages/AlarmRecord";
import AlarmHistory from "./Pages/AlarmHistory";
import Createproject from "./Pages/createproject";
import Dashboard from "./Pages/dashboard";
import DetectionBoard from "./Pages/DetectionBoard";
import EditProjectPage from "./Pages/editproject";
import HardwareManagment from "./Pages/HardwareManagement";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import NitiUserManagment from "./Pages/NitiUserManagment";
import ProjectManagement from "./Pages/ProjectManagement";
import RoleHome from "./Pages/RoleHome";
import SelectPosition from "./Pages/selectposition";
import UserManagement from "./Pages/UserManagement";
import Hardwarestatus from "./Pages/hardwareStatus";
import Lift from './Pages/LiftMonitor';
import Log from './Pages/Log';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});
  const [project, setProject] = React.useState({});
  const [routes, setRoute] = React.useState();
  function onLoginCallBack(authContext) {
    setUser(authContext.user);
    setProject(authContext.project);
  }

  React.useEffect(() => {
    const authContext = JSON.parse(localStorage.getItem('authContext'));
    if (authContext) {
      setUser(authContext.user);
      setProject(authContext.project);
    }
    else {
      if (location.pathname === '/') {
        navigate('/');
      }
      else {
        navigate('/Login');
      }
    }
  }, []);

  React.useEffect(() => {
    let counter = 0;
    if (!user.role) return;
    const routes = [];
    routes.push()
    if (user.role == 'admin') {
      routes.push(<Route key={counter++} path="/selectposition" element={<SelectPosition />} />)
      routes.push(<Route key={counter++} path="/accountmanagement" element={<AccountManagement />} />)
      routes.push(<Route key={counter++} path='/hardwarestatus' element={<Hardwarestatus />} /> )
    }
    
    if (user.role == 'niti' || user.role == 'security') {
      routes.push(<Route key={counter++} path="/DetectionBoard" element={<DetectionBoard />} />)
      routes.push(<Route key={counter++} path="/nitiusermanagement" element={<NitiUserManagment />} />)
      routes.push(<Route key={counter++} path="/alarmdetectionboard/" element={<AlarmDetectionBoard />} />)
      routes.push(<Route key={counter++} path="/alarmrecord" element={<AlarmRecord />} />)
      routes.push(<Route key={counter++} path="/alarmhistory" element={<AlarmHistory />} />)
      routes.push(<Route key={counter++} path='/hardwarestatus' element={<Hardwarestatus />} /> )
    }

    if (user.role == 'user_management') {
      routes.push(<Route key={counter++} path="/usermanagement" element={<UserManagement />} />)
      routes.push(<Route key={counter++} path="/hardwaremanagement" element={<HardwareManagment />} />)
      routes.push(<Route key={counter++} path="/projectmanagement" element={<ProjectManagement />} />)
      routes.push(<Route key={counter++} path="/dashboard" element={<Dashboard />} />)
      routes.push(<Route key={counter++} path="/createproject" element={<Createproject />} />)
      routes.push(<Route key={counter++} path="/editproject/:_id" element={<EditProjectPage />} />)
      routes.push(<Route key={counter++} path='/hardwarestatus' element={<Hardwarestatus />} /> )
      routes.push(<Route key={counter++} path='/log' element={<Log />}/>)
    }

    if (user.role == 'lift'){
      routes.push(<Route key={counter++} path="/liftmonitor" element={<Lift />} />)
      routes.push(<Route key={counter++} path="/alarmdetectionboard/" element={<AlarmDetectionBoard />} />)
    }

    setRoute(routes);
  }, [user]);


  return (
    <AuthContext.Provider value={{ user: user, project: project }}>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Login onLogin={(authContext) => {
          onLoginCallBack(authContext);
        }} />} />
        <Route path="/Login" element={<Login onLogin={(authContext) => {
          onLoginCallBack(authContext);
        }} />} />
        <Route path="/roleHome" element={<RoleHome />} />
        {routes}
        {/* <Route path="*"
          element={<RoleHome />}
        /> */}
      </Routes>
    </AuthContext.Provider>
  );
};

export default App;
