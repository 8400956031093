import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Flex, Card, CardHeader, CardBody, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";

const Activities = ({ inputObj }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [allDataFetched, setAllDataFetched] = useState(false);
    const toast = useToast({ position: "top" });
    const { t } = useTranslation();

    useEffect(() => {
        setLogs([]);
        setPageNumber(1);
        setAllDataFetched(false); // Reset the flag when inputObj changes
    }, [inputObj]);

    useEffect(() => {
        if (inputObj && Object.keys(inputObj).length !== 0 && !allDataFetched) {
            fetchLogs(inputObj);
        }
    }, [pageNumber, inputObj, allDataFetched]);

    const fetchLogs = async (inputBody) => {
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + '/log', {
                inputBody: {
                    ...inputBody,
                    page: pageNumber,
                }
            }, { withCredentials: true });

            if (res.data.length === 0 || res.data === null) {
                setLoading(false);
                setAllDataFetched(true); // Update flag when all data is fetched
                if (logs.length < 1) {
                    toast({
                        description: t("data_notfound"),
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                } else {
                    toast({
                        description: t("all_data_show"),
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                }

            }

            const formattedData = res.data.map(item => ({
                ...item,
                date: new Date(item.date).toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                })
            }));
            setLogs((prevLogs) => [...prevLogs, ...formattedData]);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };


    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        const scrollThreshold = 1; // Increase this value to require more scrolling before changing page
        if (scrollTop + clientHeight >= scrollHeight - scrollThreshold && !loading && !allDataFetched) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [allDataFetched]); // Add allDataFetched as a dependency


    return (
        <Flex justify="center" flexWrap="wrap">
            {logs.map((item, index) => (
                <Card
                    key={index}
                    mt={2}
                    mb={2}
                    ml={2}
                    mr={2}
                    width="450px"
                    height='auto'
                    align='center'
                    bg={hoveredIndex === index ? "gray.200" : "white"}
                    borderRadius="md"
                    transition="background 0.3s"
                    cursor="pointer"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                >
                    <CardHeader>
                        <Heading size='sm'>{item.date}</Heading>
                    </CardHeader>

                    {/* <CardBody textAlign="center" size="sm"> */}
                    <CardBody textAlign="center" size="sm" style={{ maxWidth: '400px', maxHeight: 'auto', overflowY: 'auto' }}>
                        {item.action_type && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>{t("action_type")}</span>: {item.action_type}
                            </Text>
                        )}
                        {item.service_type && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>{t("service_type")}</span>: {item.service_type}
                            </Text>
                        )}
                        {item.sub_action && (
                            <Text textAlign="left">
                                Sub Action: {item.sub_action}
                            </Text>
                        )}
                        {item.user && item.user.name && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>{t("name")}</span>: {item.user.name}
                            </Text>
                        )}
                        {item.user && item.user.username && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>{t("username")}</span>: {item.user.username}
                            </Text>
                        )}
                        {item.user && item.user.role && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>{t("role")}</span>: {item.user.role}
                            </Text>
                        )}
                        {item.params && Object.keys(item.params).length > 0 && (
                            <>
                                <Text textAlign="left">
                                    {Object.entries(item.params).map(([key, value], index) => (
                                        <Text key={key} textAlign="left">
                                            <span style={{ color: 'red' }}>Params: {'{'}</span> {key}: {value} <span style={{ color: 'red' }}> {'}'}</span>
                                            {index !== Object.keys(item.params).length - 1 && ", "}
                                        </Text>
                                    ))}
                                </Text>
                            </>
                        )}

                        {item.body && Object.keys(item.body).length > 0 && (
                            <>
                                {Object.entries(item.body).map(([key, value]) => (
                                    key === 'inspector' ? (
                                        <>
                                            <Text key={key} textAlign="left" color={'orange'}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                {key}:
                                            </Text>
                                            {Object.entries(value).map(([subKey, subValue]) => (
                                                <Text key={subKey} textAlign="left">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                    <span style={{ color: 'green' }}>{subKey}</span>: {subValue}
                                                </Text>
                                            ))}
                                        </>
                                    ) : (
                                        <Text key={key} textAlign="left">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                            <span style={{ color: 'orange' }}>{key}</span>: {value}
                                        </Text>
                                    )
                                ))}
                            </>
                        )}

                        {item.line_user_id && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>Line User ID</span>: {item.line_user_id}
                            </Text>
                        )}
                        {item.message && (
                            <Text textAlign="left">
                                <span style={{ color: 'red' }}>{t("message")}</span>: {item.message}
                            </Text>
                        )}
                    </CardBody>

                </Card>
            ))}
        </Flex>
    );
};

export default Activities;


