import { Box, Tooltip } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const offsetX = -25;
const offsetY = -25;

export const Alarm = ({ alarm, width, height }) => {
  const [className, setClassName] = useState("alarm_intruder");
  const { t } = useTranslation();

  useEffect(() => {
    setClassName(getClassIcon(alarm.alarm_type.identifier));
  }, [alarm])

  function getClassIcon(identifier) {
    switch (identifier) {
      case 2:
        return "alarm_fire";
        break;
      case 3:
        return "alarm_emergency";
        break;
      default:
        return "alarm_intruder";
        break;
    }
  }

  return (
    <Tooltip hasArrow label={t("house_no") + " " + alarm.address + " " + alarm.alarm_type.name}>
      <Box
        className={className}
        w="50px"
        h="50px"
        position="absolute"
        top={`${(Number(alarm.x) * height) / 100 + offsetX}px`}
        left={`${(Number(alarm.y) * width) / 100 + offsetY}px`}
      >
      </Box>
    </Tooltip>

  )
}

export default Alarm;