import React from 'react';
import {Button} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import ModalBase from './ModalBase';
import { Formik ,Form,Field } from 'formik';
import {Fieldik} from './../form/Fieldik';
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
const ModalForm = (props) => {
    const { t } = useTranslation();
    return (
    <ModalBase isCentered={props.isCentered} isOpen={props.isOpen} onClose={props.onClose} header={props.header} >
                <Formik initialValues={props.initialValues} onSubmit={props.onSubmit}>
                {(propsik) => {
                    if(props.setPropIK){
                        props.setPropIK(propsik);
                    }
                    
                    return(
                    <Form>
                        {props.children}
                        <Button mt={4} type='submit' disabled={!(propsik.isValid && propsik.dirty)} colorScheme={propsik.colorScheme || 'blue'} mr={3} isLoading={propsik.isSubmitting}>
                            {t("submit")}
                        </Button>
                    </Form>)
                }}
                </Formik>
    </ModalBase>
    )
}

export default ModalForm;

ModalForm.propTypes = {
  header:PropTypes.any,
  isOpen:PropTypes.bool,
  onClose:PropTypes.func,
  onSubmit:PropTypes.func,
  isSubmitting:PropTypes.bool,
  children:PropTypes.element,
  isCentered:PropTypes.bool,
  initialValues:PropTypes.object,
  colorScheme:PropTypes.string,
  setPropIK:PropTypes.func,
}
