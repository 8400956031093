import React from "react";
import { AuthContext } from "../contexts/AuthContext";
import {useNavigate} from 'react-router-dom';

const RoleHome = () => {
    const auth = React.useContext(AuthContext);
    const navigate = useNavigate();
    React.useEffect(() => {
        switch (auth.user.role) {
            case "admin":
              // navigate("/dashboard");
              navigate("/selectposition/");
              break;
            case "security":
              navigate("/alarmdetectionboard/");
              break;
            case "niti":
              navigate("/alarmdetectionboard/");
              break;
            case "user_management":
              // navigate("/usermanagement/");
              navigate("/dashboard/");
              break;
              case "lift":
                // navigate("/usermanagement/");
                navigate("/liftmonitor/");
                break;
            default:
              break;
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[auth])

    return (<></>);
}

export default RoleHome;