import React from "react";
import ActionButtons from "../components/ActionButtons";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  chakra,
  Icon,
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Badge,
  Avatar,
  IconButton,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import { BsPersonFill } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { AiFillTag, AiOutlineInteraction } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { useTable, usePagination, useSortBy } from "react-table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function AccountTable() {
  const navigate = useNavigate();
  const bgHead = useColorModeValue("gray.200", "gray.700");
  const bgBody = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const border = useColorModeValue("1px solid #E2E8F0", "");
  const [user, setUser] = React.useState([]);
  const auth = React.useContext(AuthContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/users/?includeProject=true",
          { withCredentials: true }
        );
        setUser(response.data);
      } catch (err) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        console.log(err);
      }
    };
    fetchData();
  }, []);

  function redirectToLogin() {
    console.log("redirect to login state /accountmanagement/");
    if (auth.user.role == "admin") {
      navigate("/Login", { state: { path: "/accountmanagement/" } });
    } else {
      navigate("/rolehome");
    }
  }

  const data = React.useMemo(() => [...user], [user]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "name",
      },
      {
        Header: t("role"),
        accessor: "role",
      },
      {
        id: "Project",
        Header: t("project"),
        accessor: (d) =>
          d.project_associate[0]?.projectName
            ? d.project_associate[0].projectName
            : "",
      },
      {
        Header: t("username"),
        accessor: "username",
      },
      {
        id: "Status",
        Header: t("status"),
        accessor: (d) => (d.isOnline ? "Online" : "Offline"),
      },
      {
        Header: t("action"),
        accessor: "action",
      },
    ],
    [user, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: false, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      //pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Table
        mt={10}
        w="1200px"
        border={border}
        borderColor="gray.200"
        borderRadius="10px"
        {...getTableProps()}
      >
        <Thead bg={bgHead} color={color}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  <Flex color={color} align="center" flexDir="row">
                    <chakra.span pr="1">
                      {column.id == "name" ? (
                        <Icon
                          w={4}
                          h={4}
                          transform="translateY(2px)"
                          as={BsPersonFill}
                        />
                      ) : column.id == "role" ? (
                        <UnlockIcon w={4} h={4} transform="translateY(-3px)" />
                      ) : column.id == "Project" ? (
                        <Icon w={4} h={4} as={AiFillTag} />
                      ) : column.id == "username" ? (
                        <Icon w={4} h={4} as={BiUserCircle} />
                      ) : column.id == "action" ? (
                        <Icon
                          w={4}
                          h={4}
                          transform="translateY(1px)"
                          as={AiOutlineInteraction}
                        />
                      ) : (
                        <Icon
                          w={5}
                          h={5}
                          transform="translateY(3px)"
                          as={HiOutlineStatusOnline}
                        />
                      )}
                    </chakra.span>
                    {column.render("Header")}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody bg={bgBody} color={color} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>
                      {cell.column.id == "name" && (
                        <Flex>
                          <Avatar size="md" name={cell.row.values.name} />
                          <Flex align="center" ml="3">
                            <Text fontWeight="bold">{cell.render("Cell")}</Text>
                          </Flex>
                        </Flex>
                      )}
                      {cell.column.id === "role" && (
                        <Badge
                          variant="outline"
                          colorScheme={
                            cell.value === "niti"
                              ? "cyan"
                              : cell.value === "security"
                                ? "red"
                                : cell.value === "admin"
                                  ? "green"
                                  : "gray" // Default color for other roles
                          }
                        >
                          {cell.render("Cell")}
                        </Badge>
                      )}
                      {cell.column.id == "Project" && (
                        <Badge variant="solid" colorScheme="blue">
                          {cell.render("Cell")}
                        </Badge>
                      )}
                      {cell.column.id == "username" && (
                        <Box>{cell.render("Cell")}</Box>
                      )}
                      {cell.column.id == "Status" && (
                        <Badge
                          colorScheme={cell.value == "Online" ? "green" : "red"}
                        >
                          {cell.render("Cell")}
                        </Badge>
                      )}
                      {cell.column.id == "action" && (
                        <ActionButtons cell={cell.row.values} />
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan="100000">
              <Flex justify="space-between" align="center">
                <Flex
                  w="230px"
                  justify="space-between"
                  align="center"
                  shrink="0"
                >
                  <Text>Show rows per page</Text>

                  <Select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                    placeholder=""
                    w="75px"
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex w="180px" justify="space-between" align="center">
                  <Text>{`Page ${pageIndex + 1} of ${pageCount}`}</Text>
                  <IconButton
                    aria-label="Previous Page"
                    bg={bgBody}
                    icon={<ChevronLeftIcon color={color} />}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <IconButton
                    aria-label="Next Page"
                    bg={bgBody}
                    color={color}
                    icon={<ChevronRightIcon />}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Flex>
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </>
  );
}

export default AccountTable;
