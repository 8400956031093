import React from "react";
import { Button, Divider, Icon, Text, Flex } from "@chakra-ui/react";
import { BiWalk } from "react-icons/bi";
import { AiFillWarning, AiFillFire } from "react-icons/ai";
import { MdOutlinePolicy,MdEmergency } from "react-icons/md";
import { t } from "i18next";

const AleartBox = ({ alarm, textColor, callback }) => {


  const [color,setColor] = React.useState("red");
  const [acknowledge,setAcknowledge] = React.useState(t("not_checking"));
  const [clear,setClear] = React.useState(t("system_alarm"));

  React.useEffect(() => {
    setAcknowledge(alarm.is_acknowledge? t("checking") : t("not_checking"));
    setClear(alarm.is_clear ? t("stop_alarm") : t("system_alarm"));
    if(!alarm.is_acknowledge && !alarm.is_clear){
      setColor("red");
    }
    else if(!alarm.is_acknowledge && alarm.is_clear){
      setColor("green");
    }
    else{
      setColor("orange");
    }
  },[alarm])

  function getIcon(identifier) {
    switch (identifier) {
      case 2:
        return (<AiFillFire/>);
      case 3:
        return (<MdEmergency/>);
      default:
        return (<BiWalk/>);
    }
  }

  return (
    <Flex
      className="alarmbox"
      flexDir="column"
      justify="space-between"
      borderRadius="xl"
      overflow="hidden"
      p={2}
      my={2}
      h="auto"
      bg={color}
    >
      <Flex h={8} color={textColor} justify="space-between" align="center">
        <Icon  w={8} h={8} as={() => getIcon(alarm.alarm_type.identifier)} />
        <Text fontSize="lg">{alarm.alarm_type.name}</Text>
        <Text fontSize="lg">{alarm.notify_start_time}</Text>
      </Flex>
      <Divider />
      <Flex color={textColor} flexDir="column" align="center">
        <Text fontSize="md">{t("area")}</Text>
        <Text fontSize="4xl">{alarm.address}</Text>
      </Flex>
      <Divider />
      <Flex h={10} color={textColor} align="center">
        <Icon mr={5} w={6} h={6} as={AiFillWarning} />
        <Text fontSize="md">{clear}</Text>
      </Flex>
      <Divider />
      <Flex h={10} color={textColor} align="center">
        <Icon mr={5} w={6} h={6} as={MdOutlinePolicy} />
        <Text fontSize="md">{acknowledge}</Text>
      </Flex>

      {!alarm.is_acknowledge && <Button mt={2} colorScheme={color} onClick={() => callback(alarm)}>
        {t("record")}
      </Button>}
    </Flex>
  );
};

export default AleartBox;
