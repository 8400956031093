/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import React from "react";
import Header from "../components/Header";
import NitiUserTable from "../components/NitiUserTable";
import AddUserButton from "../components/AddUserButton";
import Navigatebar from "../components/Navigatebar";
import { Flex, Heading, Container, useToast } from "@chakra-ui/react";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const NitiUserManagment = () => {

  const navigate = useNavigate();
  const [users, setUsers] = React.useState([]);
  const auth = React.useContext(AuthContext);
  const toast = useToast();
  const { t } = useTranslation();

  async function fetchUsers() {
    if (!auth.user._id) return [];
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + "/projects/user/" + auth.user._id, { withCredentials: true });
      if (Array.isArray(response.data.users)) {
        const newUsers = Array.from(response.data.users);
        // const index = newUsers.map(u => u.username).indexOf(auth.user.username);
        // if(index != -1){
        //   newUsers.splice(index,1);
        // }
        return newUsers;
      }
    }
    catch (err) {
      throw err;
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const newUsers = await fetchUsers();
        setUsers(newUsers);
      }
      catch (err) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        console.log(err);
      }
    };
    fetchData();
  }, [auth]);

  async function handleCreateUserSubmitAsync(newUser) {
    try {
      newUser.role = 'security';
      const response = await axios.post(process.env.REACT_APP_BASE_URL + "/projects/" + auth.project._id + "/assign_user", newUser, { withCredentials: true });
      if (response.status == 200) {
        toast({ position: 'top-center', title: 'เจ้าหน้าที่รักษาความปลอดภัย สำเร็จ', status: 'success', duration: 5000, isClosable: true, });
        const newUsers = await fetchUsers();
        setUsers(newUsers);
        return true;
      }
    }
    catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        if (err.response?.status == 400) {
          toast({ position: 'top-center', title: 'เจ้าหน้าที่รักษาความปลอดภัยล้มเหลว', description: err.response?.data.message, status: 'error', duration: 5000, isClosable: true, });
        }
      }
    }
    return false;
  }

  async function handleEditUserSubmit(username, editUser) {
    try {
      const updateResponse = await axios.put(process.env.REACT_APP_BASE_URL + "/users/" + username, editUser, { withCredentials: true });
      if (updateResponse.status == 200) {
        toast({ position: 'top-center', title: t("edit_user_success"), status: 'success', duration: 5000, isClosable: true, });
        const newUsers = await fetchUsers();
        setUsers(newUsers);
        return true;
      }
    }
    catch (err) {
      console.error(err);
      if (err.response) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        else {
          toast({ position: 'top-center', title: t("edit_user_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
        }
      }

    }
  }

  async function handleDeleteUserSubmit(deleteUser) {
    try {
      const updateResponse = await axios.delete(process.env.REACT_APP_BASE_URL + "/users/" + deleteUser.username, { withCredentials: true });
      if (updateResponse.status == 200) {
        toast({ position: 'top-center', title: t("delete_user_success"), status: 'success', duration: 5000, isClosable: true, });
        const newUsers = await fetchUsers();
        setUsers(newUsers);
      }
    }
    catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        if (err.response?.status == 400) {
          toast({ position: 'top-center', title: t("delete_user_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
        }
        if (err.response?.status == 500) {
          toast({ position: 'top-center', title: t("delete_user_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
        }
      }

    }
  }

  function redirectToLogin() {
    console.log('redirect to login state /nitiusermanagement/');
    if (auth.user.role == 'niti' || auth.user.role == 'security') {
      navigate('/Login', { state: { path: "/nitiusermanagement/" } });
    }
    else {
      navigate('/rolehome');
    }
  }

  return (
    <>
      <Header />
      <Navigatebar page={auth.user.role == 'niti' ? t("niti_user_management") : t("view_niti_info")} />
      <Container maxW="1200px" mt={10}>
        <Heading fontSize="36">{auth.user.role == 'niti' ? t("niti_user_management") : t("view_niti_info")}</Heading>
        <Flex justify="flex-end">
          {auth.user.role == 'niti' && <AddUserButton OnCreateUserSubmitAsync={async (newUser) => handleCreateUserSubmitAsync(newUser)} />}
        </Flex>
        <Flex justify="center" w="auto">
          <NitiUserTable users={users} onDelete={handleDeleteUserSubmit} onEdit={handleEditUserSubmit} user_name={auth.user.username} />
        </Flex>
      </Container>
    </>
  );
};

export default NitiUserManagment;
