import React, { useState, useEffect } from 'react';
import { Flex, Select, Box, Button, useToast } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
// const actions = [ "CREATE", "READ", "READ ALL", "UPDATE", "DELETE", "LOGIN", "LOGOUT", "FORCE LOGOUT", "RESET PASSWORD", "HARDWARE ALARM", "ACKNOWLEDGE", "RECEIVE_MESSAGE"]

const Logfilter = ({ onInput }) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [action, setAction] = useState(null);
    const toast = useToast({ position: "top" });
    const actions = ["CREATE", "READ", "READ ALL", "UPDATE", "DELETE", "LOGIN", "LOGOUT", "FORCE LOGOUT", "RESET PASSWORD", "ACKNOWLEDGE", "RECEIVE_MESSAGE"]
    const { t } = useTranslation();

    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_BASE_URL + '/users', { withCredentials: true });
                setUsers(res.data);
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();

    }, []);

    const handleStartDateChange = (date) => {
        // Set start date to the beginning of the selected day in UTC timezone
        const start = new Date(date);
        start.setUTCHours(0, 0, 0, 0);
        setStartDate(start);

        // Calculate the end date based on the selected start date
        const nextMonth = new Date(date);
        const nextDate = nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 1);
        if (nextDate >= Date.now()) {
            // If the calculated end date is in the future, set it to the current date
            setEndDate(new Date());
        } else {
            // Otherwise, set it to the calculated end date
            setEndDate(nextMonth);
        }
    };

    const handleEndDateChange = (date) => {
        // Set end date to the end of the selected day in UTC timezone
        const end = new Date(date);
        end.setUTCHours(23, 59, 59, 999);
        setEndDate(end);
    };

    const handleReset = () => {
        setSelectedUser(null)
        setStartDate(null);
        setEndDate(null);
        setAction(null);
        onInput(null);
    };

    const handleApply = async () => {
        try {
            if (!selectedUser && !action && (!startDate || !endDate)) {
                toast({
                    description: t("please_select_least"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            if (selectedUser || action && (!startDate || !endDate)) {
                toast({
                    description: t("view_data_month"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            }

            const durationInDays = Math.abs((endDate - startDate) / (1000 * 60 * 60 * 24));
            const durationInMonths = durationInDays / 31;

            if (durationInMonths > 1) {
                toast({
                    description: t("view_data_not_more_month"),
                    status: "error",
                    duration: 1000,
                    isClosable: true,
                });
                setStartDate(null);
                setEndDate(null);
            } else {
                const input_obj = {
                    username: selectedUser,
                    startDate: startDate,
                    endDate: endDate,
                    // page: 1,
                    action: action
                };
                onInput(input_obj);
            }
        } catch (err) {
            console.log(err);
        }
    };



    const handleUsernameChange = (e) => {
        const selectedUser = e.target.value;
        setSelectedUser(selectedUser);
    };

    const handleActionChange = (e) => {
        const selectedAction = e.target.value;
        setAction(selectedAction);

    }
    return (
        <>
            <Flex>
                <Select placeholder={t("select_user")} w="200px" onChange={handleUsernameChange} value={selectedUser || ''}>
                    {users.map((user, index) => (
                        <option key={index} value={user.username}>
                            {user.username}
                        </option>
                    ))}
                </Select>
            </Flex>
            <span style={{ margin: "0 10px" }}> </span>

            <Flex>
                <Select placeholder={t("select_type_action")} w="200px" onChange={handleActionChange} value={action || ''}>
                    {actions.map((action, index) => (
                        <option key={index} value={action}>
                            {action}
                        </option>
                    ))}
                </Select>
            </Flex>
            <span style={{ margin: "0 10px" }}> </span>

            <Flex alignItems="center">

                <Box border="1px solid #CBD5E0" borderRadius="md" p={2} mr={2}>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={t("start_date")}
                        dateFormat="dd/MM/yy"
                        maxDate={new Date()} // Block future dates
                        style={{ width: "100%" }}
                    />
                </Box>

                <Box border="1px solid #CBD5E0" borderRadius="md" p={2} ml={2}>
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={t("end_date")}
                        dateFormat="dd/MM/yy"
                        minDate={startDate}
                        maxDate={new Date()} // Block future dates
                        style={{ width: "100%" }}
                    />
                </Box>

                <span style={{ margin: "0 10px" }}> </span>
                <Flex justifyContent="flex-end">
                    <Button mr={2} colorScheme="teal" onClick={handleApply} size="md">
                        {t("submit")}
                    </Button>
                    <Button colorScheme="red" onClick={handleReset} size="md">
                        {t("clear")}
                    </Button>
                </Flex>
            </Flex>
        </>
    );
};

export default Logfilter;
