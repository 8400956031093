import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Select } from "@chakra-ui/react";
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from "react-i18next";

const ProjectsList = ({ onProjectSelect }) => {
    const auth = useContext(AuthContext);
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const [selectedProjectID, setSelectedProjectID] = useState(null)


    useEffect(() => {
        async function fetchData() {
            try {
                const res = await axios.get(process.env.REACT_APP_BASE_URL + '/projects', { withCredentials: true });
                setProjects(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchData();

    }, [auth]);

    function handleSelectChange(e) {
        const projectId = e.target.value;
        setSelectedProjectID(projectId);
        onProjectSelect(projectId);
    };

    return (
        <>
            <div>
                <Select placeholder={t("selcet_project")} w="275px" onChange={handleSelectChange} value={selectedProjectID || ''}>
                    {projects.map((project, index) => (
                        <option key={index} value={project._id}>
                            {project.projectName}
                        </option>
                    ))}
                </Select>
            </div>
        </>
    );
};

export default ProjectsList;




