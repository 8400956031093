import React from "react";
import { Box, Flex, Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const SelectProjectBar = (props) => {
  const { t } = useTranslation();
  return (
    <Box w="600px" my="20px">
      <Select placeholder={t("selcet_project")} onChange={props.onSelect}>
        {props.projects.map((project) => (
          <option key={project._id} value={project._id}>{project.projectName}</option>
        ))}
      </Select>
    </Box>
  );
};

export default SelectProjectBar;
