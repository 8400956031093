import React from "react";
import Header from "../components/Header";
import AlarmRecordTable from "../components/alarm/AlarmRecordTable";
import Navigatebar from "../components/Navigatebar";
import { Flex, Heading, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const AlarmRecord = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Navigatebar page={t("save_noti_history")} />
      <Container maxW="1200px" mt={10}>
        <Heading fontSize="36">{t("save_noti_history")}</Heading>
        <Flex justify="center" w="auto">
          <AlarmRecordTable />
        </Flex>
      </Container>
    </>
  );
};

export default AlarmRecord;
