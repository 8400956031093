/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useNavigate} from "react-router-dom";
import axios from "axios";
import {Box, Flex, Image, Text, Container, useColorModeValue, IconButton,useToast,useDisclosure} from "@chakra-ui/react";
import Header from "../components/Header";
import AleartBox from "../components/alarm/AleartBox";
import AcknowledgeAlarmForm from "../components/alarm/AcknowledgeAlarmForm"
import { Clock } from "../components/Clock";
import alarm_audio from './../audios/alarmsound4.mp3';
import { AuthContext } from '../contexts/AuthContext';
import { ImVolumeMedium,ImVolumeMute2  } from 'react-icons/im';
import { Alarm } from '../components/alarm/Alarm'
import MuteNotification from "../components/alarm/MuteNotification";
import React from 'react';
import { useTranslation } from "react-i18next";

const property = {
  imageUrl:
    "https://upload.wikimedia.org/wikipedia/commons/3/33/%E0%B9%81%E0%B8%AA%E0%B8%99%E0%B8%AA%E0%B8%B4%E0%B8%A3%E0%B8%B4-%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94-%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99-logo-en.jpg",
  imageAlt: "AheadAll",
  imageUrl2:
    "https://onlinebooking.sansiri.com/stocks/project/d850x550/tw/6l/fuqctw6lp8/master-plan-02.jpg",
  imageAlt2: "AheadAll",
};

const alarmAudio = new Audio(alarm_audio);
alarmAudio.loop = true;
if(process.env.REACT_APP_ENABLE_ALARM_SOUND !== "true"){
  alarmAudio.volume = 0;
}

let timeout = 250;
let clean_up = false;

const AlarmDetectionBoard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const toastIdRef = React.useRef()
  const navigate = useNavigate();
  const bg = useColorModeValue("white", "gray.700");
  const auth = React.useContext(AuthContext);
  const [listAlarmElements, setlistAlarmElements] = React.useState([]);
  const [alarms, setAlarms] = React.useState([]);
  const [alarm, setAlarm] = React.useState({});
  const [isDialogOpen, setDialog] = React.useState(false);
  const [ismute, setMute] = React.useState(false);
  const timerId = React.useRef(null);
  const webSocket = React.useRef(null);

  const [width, setWidth] = React.useState("");
  const [height, setHeight] = React.useState("");
  const projectImage = React.useRef(null);
  const { t } = useTranslation();

  async function fetchAndUpdateAlarms() {
    if(!auth?.project?._id) return [];
    try{
      const alarmElements = [];
      const alarms = [];
      const res = await axios.get(process.env.REACT_APP_BASE_URL + '/alarms/' + auth.project._id ,{withCredentials: true});
        res.data.forEach(alarm => {
          if(alarm.is_clear && alarm.is_acknowledge) return;
          alarmElements.push(<AleartBox key={alarm._id} alarm={alarm} textColor="white" callback={alarm_click} />)
          alarms.push(alarm);
        });
        //alarmElements.reverse();
        setlistAlarmElements([...alarmElements]);
        setAlarms(alarms);
        requestPlayAlarm(alarms);
    }
    catch(err){
      if(err.response?.status === 401){
        redirectToLogin();
      }
      throw err;
    }
  }

  const setImageWidthAndHight = () => {
    if(projectImage.current?.offsetHeight != null && projectImage.current?.offsetWidth != null){
      setWidth(projectImage.current.offsetWidth);
      setHeight(projectImage.current.offsetHeight);
    }
  };

  const handleResize = () => {
    setImageWidthAndHight();
  }
  React.useEffect(() => {
    clean_up = false;
    connect();
    onOpen();
    blurBackground("5px");
    window.addEventListener("resize", handleResize, false);
  }, []);

  React.useEffect(() => {
    if(!auth.user.username) return;
    try {
      const wrapper = async () => {
        await fetchAndUpdateAlarms()
      };
      wrapper();
    }
    catch(err) {
      console.error(err);
      if(err.response?.status === 401){
        redirectToLogin();
      }
      else if(auth.user?.role === 'niti' || auth.user?.role === 'security'){
        setMute(true);
      }
    }
  },[auth.user]);

  React.useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  React.useEffect(() => {
    if(ismute){
      showToast();
      alarmAudio.pause();
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setMute(false);
      },auth.user?.alarm_duration === undefined?5 * 1000 * 60 : auth.user?.alarm_duration * 1000 * 60);
    }
    else{
      hideToast();
      requestPlayAlarm(alarms);
    }
  }, [ismute]);

  function blurBackground(value){
    document.getElementById("root").style.filter = `blur(${value})`;
  }

  async function requestPlayAlarm(alarms = null) {
    const not_clear = alarms.filter(alarm => !alarm.is_clear);
    try {
        if(not_clear.length > 0)  {
            if(!alarmAudio?.paused) return;
            await alarmAudio?.play();
            setMute(false);
        }
        else{
          await alarmAudio?.pause();
        }
      }
    catch(err){
      console.log(err);
      if(err.response?.status === 401){
        redirectToLogin();
      }
      else if(auth.user?.role === 'niti' || auth.user?.role === 'security'){
        setMute(true);
      }
    }
  }
  

  function cleanUp() {
    clean_up = true;
    console.log('clean up resource...');
    if(webSocket.current){
      webSocket.current.close();
    }
    alarmAudio.pause();
    hideToast();
    blurBackground("0px");
  }

  function hideToast() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  function showToast() {
    toastIdRef.current = toast({ position: 'top-center', title: t("turn_off_sound"), status: 'warning', duration: null});
  }

  function redirectToLogin(){
    console.log('redirect to login state /alarmdetectionboard/');
    if(auth.user.role === 'security' || auth.user.role === 'niti'){
      navigate('/Login', {state : { path: "/alarmdetectionboard/"}});
    } 
    else{
      navigate('/rolehome');
    }
  }

  function connect() {
    if(clean_up) return;
    webSocket.current = new WebSocket(process.env.REACT_APP_BASE_SOCKETURL);
    webSocket.current.onclose = onclose;
    webSocket.current.onerror = onerror;
    webSocket.current.onopen = onopen;
    webSocket.current.onmessage = onmessage;
  }

  function onopen(ev) {
    console.log("onopen");
  }

  async function onmessage(ev) {
    try{
      console.log('onmessage');
      await fetchAndUpdateAlarms();
      setMute(false);
    }
    catch(err) {
      console.log(err);
    }
  }

  function onclose (ev) {
    if(clean_up) return;
    const reconnectDelay = Math.min(20000,timeout+=timeout);
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', ev.reason);
    setTimeout(() => {
      connect();
      console.log('reconnect in ' + reconnectDelay/1000 + ' second');
    }, reconnectDelay);
  }

  function onerror (err) {
    const reconnectDelay = Math.min(20000,timeout+=timeout);
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    setTimeout(() => {
      connect();
      console.log('reconnect in ' + reconnectDelay/1000 + ' second');
    }, reconnectDelay);
  }

  function alarm_click(alarm) {
    setAlarm(alarm);
    setDialog(true);
  }

  async function handlAcknowledgeSubmit(user,values,actions) {
    const inspector = auth?.project?.users.find(u => { return u._id == values.inspectorId});
    let record = {
      reason: values.reason,
      remark: values.remark,
      user: user,
      inspector: inspector
    }
    setDialog(false);
    try{
      const res = await axios.post(process.env.REACT_APP_BASE_URL + '/alarms/acknowledge/' + alarm._id,record ,{withCredentials: true});
      if(res.status === 200){
        toast({ position: 'top-center', title: t("register_success"), status: 'success', duration: 5000, isClosable: true,});
        await fetchAndUpdateAlarms();
        actions.setSubmitting(false);
      }
    }
    catch (err){
      if(err.response){
        if(err.response?.status === 401){
          redirectToLogin();
        }
        toast({ position: 'top-center', title: t("register_failed"),description : err.response?.data.message, status: 'success', duration: 5000, isClosable: true,});
      }

      console.error(err);
    }
  }
  function handleImageLoad() {
    setImageWidthAndHight();
  }

  function handleOnMuteNotificationClick(e) {
    blurBackground("0px");
    onClose();
  }
 
  return (
    <Box>
      <MuteNotification isOpen={isOpen} onClick={handleOnMuteNotificationClick} onClose={handleOnMuteNotificationClick}/>
      <AcknowledgeAlarmForm users={auth?.project?.users} user={auth?.user} isOpen={isDialogOpen} onSubmit={handlAcknowledgeSubmit} cancelClickCallback={() => setDialog(false)}/>
      {auth.project.logo !== undefined && <Header/>}
      {/* <Navigatebar page="Alarm Detection Board" /> */}
      <Container maxW="1900px" mt={1}>
        <Flex justify="space-between">
          <Box  mr="5px" w="100%" h="87vh" boxShadow="md" position="relative">
            {auth.project.image !== undefined &&<Image h="87vh" onLoad={() => handleImageLoad()} ref={projectImage} src={`data:image/png;base64,${auth.project.image}`} alt={property.imageAlt2} />}
            {alarms.map(alarm => {
              if(alarm.is_clear) return (<></>);
              return (<Box key={alarm._id}>
                <Alarm  alarm={alarm} width={width} height={height}/>
                </Box>)
            })}
          </Box>
          <Flex h="87vh" flexDir="column" align="center" w="450px" bg={bg} boxShadow="xl" alignItems="stretch">
            <Box w="350px" p={3} h="auto" boxShadow="md">
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <Box w="20px" h="20px" borderRadius="full" bg="green"></Box>
                  <Text ml={2} color="green" fontSize="1.2em">
                    {t("online")}
                  </Text>
                </Flex>
                <Clock/>
              </Flex>
              <Flex justify="center" align="center">
                <IconButton className="volume" size='lg' variant='ghost' icon={!ismute?<ImVolumeMedium />:<ImVolumeMute2 />} onClick={() => setMute(!ismute)}></IconButton >
              </Flex>
            </Box>
            <Box  overflowX="hidden" w="350px" h="auto" boxShadow="md" flex={1}>
            <Flex   p={5} align="center" justify="center" flexDir="column" >
              <ul>{listAlarmElements}</ul>
            </Flex>
            </Box>
          </Flex>
        </Flex>
      </Container>

    </Box>
  );
};

export default AlarmDetectionBoard;