/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useContext, useRef, useState, useEffect } from "react";
import axios from "axios";
import { Flex, Container, Box, useColorModeValue, IconButton, Text, useToast, useDisclosure } from "@chakra-ui/react";
import AcknowledgeAlarmForm from "../components/alarm/AcknowledgeAlarmForm"
import Header from "../components/Header";
import { AuthContext } from '../contexts/AuthContext';
import { Clock } from "../components/Clock";
import { ImVolumeMedium, ImVolumeMute2 } from 'react-icons/im';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import normalMarker from '../images/normal_mark.png';
import alertMarker from '../images/alert_mark.png'
import L from 'leaflet';
import alarm_audio from './../audios/alarmsound4.mp3';
import { useNavigate } from "react-router-dom";
import MuteNotification from "../components/alarm/MuteNotification";
import AleartBox from "../components/alarm/AleartBox";
import { useTranslation } from "react-i18next";

const alarmAudio = new Audio(alarm_audio);
alarmAudio.loop = true;
if (process.env.REACT_APP_ENABLE_ALARM_SOUND !== "true") {
    alarmAudio.volume = 0;
}

let timeout = 250;
let clean_up = false;

const isAlert = false;


const Lift = () => {

    const auth = useContext(AuthContext);

    const timerId = useRef(null);
    const webSocket = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [listAlarmElements, setlistAlarmElements] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [alarm, setAlarm] = useState({});
    const toast = useToast();
    const toastIdRef = useRef()
    const mapRef = useRef(null);
    const bg = useColorModeValue("white", "gray.700");
    const navigate = useNavigate();
    const [ismute, setMute] = useState(false);
    const AAPosition = [13.736717, 100.523186];
    const [isDialogOpen, setDialog] = useState(false);
    const [markers, setMarkers] = useState([]);
    const { t } = useTranslation();
    
    const fetchMakersAsync = async () => {
        if (!auth.project._id) return [];
        try {
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + "/markers/" + auth.project._id, { withCredentials: true }
            );
            return response.data;
        }
        catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        return () => {
            cleanUp();
        };
    }, []);

    useEffect(() => {
        clean_up = false;
        connect();
        onOpen();
        blurBackground("5px");
    }, []);

    useEffect(() => {
        const fetchMarker = async () => {
            try {
                const markers = await fetchMakersAsync();
                setMarkers(markers);
            }
            catch (err) {
                if (err.response?.status == 401) {
                    redirectToLogin();
                }
                console.log(err);
            }
        }
        fetchMarker();
    }, [])

    const blurBackground = (value) => {
        document.getElementById("root").style.filter = `blur(${value})`;
    }

    useEffect(() => {
        if (!auth.user.username) return;
        try {
            const wrapper = async () => {
                await fetchAndUpdateAlarms()
            };
            wrapper();
        }
        catch (err) {
            console.error(err);
            if (err.response?.status === 401) {
                redirectToLogin();
            }
            else if (auth.user?.role === 'lift') {
                setMute(true);
            }
        }
    }, [auth.user]);

    const hideToast = () => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
        }
    }
    const showToast = () => {
        toastIdRef.current = toast({ position: 'top-center', title: t("noti_turned_off"), status: 'warning', duration: null });
    }

    const cleanUp = () => {
        clean_up = true;
        console.log('clean up resource...');
        if (webSocket.current) {
            webSocket.current.close();
        }
        alarmAudio.pause();
        hideToast();
        blurBackground("0px");
    }

    useEffect(() => {
        if (ismute) {
            showToast();
            alarmAudio.pause();
            clearTimeout(timerId.current);
            timerId.current = setTimeout(() => {
                setMute(false);
            }, auth.user?.alarm_duration === undefined ? 5 * 1000 * 60 : auth.user?.alarm_duration * 1000 * 60);
        }
        else {
            hideToast();
            requestPlayAlarm(alarms);
        }
    }, [ismute]);

    const fetchAndUpdateAlarms = async () => {
        if (!auth?.project?._id) return [];
        try {
            const alarmElements = [];
            const alarms = [];
            const res = await axios.get(process.env.REACT_APP_BASE_URL + '/alarms/' + auth.project._id, { withCredentials: true });
            res.data.forEach(alarm => {
                if (alarm.is_clear && alarm.is_acknowledge) return;
                alarmElements.push(<AleartBox key={alarm._id} alarm={alarm} textColor="white" callback={alarm_click} />)
                alarms.push(alarm);
            });
            //alarmElements.reverse();
            setlistAlarmElements([...alarmElements]);
            setAlarms(alarms);
            requestPlayAlarm(alarms);
        }
        catch (err) {
            if (err.response?.status === 401) {
                redirectToLogin();
            }
            throw err;
        }
    }

    const redirectToLogin = () => {

        if (auth.user.role === 'lift') {
            navigate('/Login', { state: { path: "/liftmonitor/" } });
        }
        else {
            navigate('/rolehome');
        }
    }

    const requestPlayAlarm = async (alarms = null) => {
        const not_clear = alarms.filter(alarm => !alarm.is_clear);
        try {
            if (not_clear.length > 0) {
                if (!alarmAudio?.paused) return;
                await alarmAudio?.play();
                setMute(false);
            }
            else {
                await alarmAudio?.pause();
            }
        }
        catch (err) {
            console.log(err);
            if (err.response?.status === 401) {
                redirectToLogin();
            }
            else if (auth.user?.role === 'lift') {
                setMute(true);
            }
        }
    }
    const connect = () => {
        if (clean_up) return;
        webSocket.current = new WebSocket(process.env.REACT_APP_BASE_SOCKETURL);
        webSocket.current.onclose = onclose;
        webSocket.current.onerror = onerror;
        webSocket.current.onopen = onopen;
        webSocket.current.onmessage = onmessage;
    }

    const onopen = (ev) => {
        console.log("onopen");
    }

    const onmessage = async (ev) => {
        try {
            console.log('onmessage');
            await fetchAndUpdateAlarms();
            setMute(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    const onclose = (ev) => {
        if (clean_up) return;
        const reconnectDelay = Math.min(20000, timeout += timeout);
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', ev.reason);
        setTimeout(() => {
            connect();
            console.log('reconnect in ' + reconnectDelay / 1000 + ' second');
        }, reconnectDelay);
    }

    const onerror = (err) => {
        const reconnectDelay = Math.min(20000, timeout += timeout);
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        setTimeout(() => {
            connect();
            console.log('reconnect in ' + reconnectDelay / 1000 + ' second');
        }, reconnectDelay);
    }

    const alarm_click = (alarm) => {
        setAlarm(alarm);
        setDialog(true);
    }

    const handlAcknowledgeSubmit = async (user, values, actions) => {
        const inspector = auth?.project?.users.find(u => { return u._id == values.inspectorId });
        let record = {
            reason: values.reason,
            remark: values.remark,
            user: user,
            inspector: inspector
        }
        setDialog(false);
        try {
            const res = await axios.post(process.env.REACT_APP_BASE_URL + '/alarms/acknowledge/' + alarm._id, record, { withCredentials: true });
            if (res.status === 200) {
                toast({ position: 'top-center', title: t("register_success"), status: 'success', duration: 5000, isClosable: true, });
                await fetchAndUpdateAlarms();
                actions.setSubmitting(false);
            }
        }
        catch (err) {
            if (err.response) {
                if (err.response?.status === 401) {
                    redirectToLogin();
                }
                toast({ position: 'top-center', title: t("register_failed"), description: err.response?.data.message, status: 'success', duration: 5000, isClosable: true, });
            }

            console.error(err);
        }
    }
    const handleOnMuteNotificationClick = (e) => {
        blurBackground("0px");
        onClose();
    }

    const handleMarkerClick = (marker) => {
        console.log('Yo:', marker);
    }

    return (
        <Box>
            <MuteNotification isOpen={isOpen} onClick={handleOnMuteNotificationClick} onClose={handleOnMuteNotificationClick} />
            <AcknowledgeAlarmForm users={auth?.project?.users} user={auth?.user} isOpen={isDialogOpen} onSubmit={handlAcknowledgeSubmit} cancelClickCallback={() => setDialog(false)} />
            {auth.project.logo !== undefined && <Header />}
            <Container maxW="1900px" mt={1}>
                <Flex justify="space-between">
                    {/* Map on the left */}
                    <Box mr="5px" w="100%" h="87vh" boxShadow="md" position="relative">
                        <MapContainer center={AAPosition} zoom={6} minZoom={6} ref={mapRef} style={{ height: "100%", width: "100%" }}>
                            <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                            {markers.map((marker, index) => (
                                <Marker
                                    key={index}
                                    position={[marker.x, marker.y]}
                                    icon={new L.Icon({
                                        iconUrl: alarms.some(alarm => alarm.macaddress === marker.macaddress) ? alertMarker : normalMarker,
                                        iconSize: [40, 40],
                                        iconAnchor: [20, 40], // Adjust the anchor point if needed
                                        // className: alarms.some(alarm => alarm.macaddress === marker.macaddress) ? 'flash-scale-animation' : '' // Apply animation class conditionally
                                    })}
                                // eventHandlers={{
                                //     click: () => {
                                //         handleMarkerClick(marker); // Call your click handler function with the marker data
                                //     }
                                // }}
                                >

                                    <Popup autoPan={false}>
                                        {marker.macaddress}
                                    </Popup>

                                </Marker>

                            ))}

                        </MapContainer>
                    </Box>

                    <Flex h="87vh" flexDir="column" align="center" w="450px" bg={bg} boxShadow="xl" alignItems="stretch">
                        <Box w="350px" p={3} h="auto" boxShadow="md">
                            <Flex justify="space-between" align="center">
                                <Flex align="center">
                                    <Box w="20px" h="20px" borderRadius="full" bg="green"></Box>
                                    <Text ml={2} color="green" fontSize="1.2em">
                                        Online
                                    </Text>
                                </Flex>
                                <Clock />
                            </Flex>
                            <Flex justify="center" align="center">
                                <IconButton className="volume" size='lg' variant='ghost' icon={!ismute ? <ImVolumeMedium /> : <ImVolumeMute2 />} onClick={() => setMute(!ismute)}></IconButton >
                            </Flex>
                        </Box>
                        <Box overflowX="hidden" w="350px" h="auto" boxShadow="md" flex={1}>
                            <Flex p={5} align="center" justify="center" flexDir="column" >
                                <ul>{listAlarmElements}</ul>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>
            </Container>
        </Box >
    );
}

export default Lift;
