import React from "react";
import Header from "../components/Header";
import CreateProject from "../components/CreateProject";
import Navigatebar from "../components/Navigatebar";
import { useTranslation } from "react-i18next";

const Createproject = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Navigatebar page={t("create_project")} />
      <CreateProject />
    </>
  );
};

export default Createproject;
