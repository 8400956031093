import React, { useState } from 'react';
import { Flex, Container, Heading, Box } from "@chakra-ui/react";
import Header from "../components/Header";
import Navigatebar from "../components/Navigatebar";
import Activities from '../components/Activities';
import Logfilter from "../components/LogFilter";
import { useTranslation } from "react-i18next";

const Log = () => {
    const [inputBody, setInputBody] = useState(null);
    const { t } = useTranslation();

    const handleInput = (newInput) => {
        try {
            setInputBody(newInput);
        } catch (err) {
            console.log(err)
        }

    }
    return (
        <>
            <Header />
            <Navigatebar page={t("activities_log")} />
            <Container maxW="1200px" mt={10} mx="auto">
                <Heading mb={8} fontSize="36">{t("activities_log")}</Heading>
                <Flex mb={8} justify="center" w="auto">
                    <Logfilter onInput={handleInput} />
                </Flex>
            </Container>
            <Flex justify="center" w="auto">
                <Activities inputObj={inputBody} />
            </Flex>
        </>
    )
}

export default Log;

