import React,{useContext} from "react";
import {
  Container, Text, Modal, ModalOverlay, ModalContent, ModalBody,
  Flex, Image
} from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { AuthContext } from '../../contexts/AuthContext';
import eyeImage from '../../images/eye.gif';
import { useTranslation } from "react-i18next";


const MuteNotification = ({ isOpen, onClick, onClose }) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  
  var text = auth.user.role === 'lift'?'Online Lift Monitoring':'Online Alarm Detection';
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered >
      <ModalOverlay />
      <ModalContent width={800} maxWidth={1200}>
        <ModalBody onClick={onClick} bg="rgb(231,231,231)" borderRadius={10}>
          <Container width={800}>
            <Flex fontSize={48} justify="space-around" flexDir="column" mt={5}>
              <Text align="center" colorScheme="gray">{t("click_for_open")}</Text>
              <Text align="center" colorScheme="gray">{text}</Text>
              <Image src={eyeImage} />
            </Flex>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MuteNotification;

MuteNotification.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
}