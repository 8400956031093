import React from 'react'
import { Flex, Text,Image } from "@chakra-ui/react";
import icon from '../images/AA LOGO Transparent.png'

export function Footer(props){
    return(
        
        <Flex
            pl="5"
            pr="5"
            h="3em"
            mt="2em"
            justify="space-between"
            alignItems="center"
            style={{
                boxShadow:"0 -4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)"
            }}
            >
            <Flex alignItems="center">
                <Text>Powered by </Text>
                <Image w='64px' h='36px' src={icon}></Image>
            </Flex>
            <Text>Copyright © 2022 AheadAll Co., Ltd</Text>
        </Flex>
    )
}