/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
import React from "react";
import Header from "../components/Header";
import UserManagementTable from "../components/UserManagementTable";
import AddUserButton from "../components/AddUserButton";
import Navigatebar from "../components/Navigatebar";
import { Flex, Heading, Container,useToast } from "@chakra-ui/react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const UserManagment = () => {

  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const toast = useToast(); 
  const [users, setUsers] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const { t } = useTranslation();

  async function fetchUsers(){
    if(!auth.user) return [];
    try{
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/users/?includeProject=true",{withCredentials: true});
        if(Array.isArray(response.data)){
          const fetchUsers = Array.from(response.data);
          return fetchUsers
          // return removeSelf(fetchUsers);
        }
    }
    catch(err){
      throw err;
    }    
  }

  async function fetchProjects(){
    if(!auth.user) return [];
    try{
      const response = await axios.get(process.env.REACT_APP_BASE_URL + "/projects/",{withCredentials: true});
      return response.data;
    }
    catch(err){
      throw err;
    }    
  }

  function removeSelf(fetchUsers) {
    const index = fetchUsers.map(u => u.username).indexOf(auth.user.username);
    if(index != -1){
      fetchUsers.splice(index,1);
    }
    return fetchUsers;
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try{
          const users = await fetchUsers();
          setUsers(users);
          const projects = await fetchProjects();
          setProjects(projects);
      }
      catch(err){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        console.log(err);
      }
    };
    fetchData();
  }, [auth]);

  async function handleCreateUserSubmit(newUser) {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + "/users/create", newUser,{withCredentials: true});
      if(response.status == 200){
        toast({ position: 'top-center', title: t("add_niti_success"), status: 'success', duration: 5000, isClosable: true,});
        const users = await fetchUsers();
        setUsers(users);
        return true;
      }
    }
    catch (err){
      console.log(err);
      
      if(err.response){
        toast({ position: 'top-center', title: t("add_niti_failed"),description:err.response.data.message, status: 'error', duration: 5000, isClosable: true,});
        if(err.response?.status == 401){
          redirectToLogin();
        }
      }

    }
  }


  function redirectToLogin(){
    console.log('redirect to login state /usermanagement/');
    if(auth.user.role == 'user_management'){
      navigate('/Login', {state : { path: "/usermanagement/"}});
    } 
    else{
      navigate('/rolehome');
    }
  }

  async function handleEditUserSubmit(username, editUser){
    try {
      const updateResponse = await axios.put(process.env.REACT_APP_BASE_URL + "/users/" + username, editUser,{withCredentials: true});
      if(updateResponse.status == 200){
        toast({ position: 'top-center', title: t("edit_data_success"), status: 'success', duration: 5000, isClosable: true,});
        const users = await fetchUsers();
        setUsers(users);
        return true;
      }
    }
    catch (err){
      console.error(err);
      if(err.response){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        else{
          toast({ position: 'top-center', title: t("edit_data_failed"),description:err.response.data.message, status: 'error', duration: 5000, isClosable: true,});
        }
      }

    }
  }

  async function handleAssignSubmit(projectId,user){
    try {
      const updateResponse = await axios.post(process.env.REACT_APP_BASE_URL + "/projects/" + projectId + "/assign_project", user,{withCredentials: true});
      if(updateResponse.status == 200){
        toast({ position: 'top-center', title: t("assign_project_success"), status: 'success', duration: 5000, isClosable: true,});
        const users = await fetchUsers();
        setUsers(users);
        return true;
      }
    }
    catch (err){
      console.error(err);
      if(err.response){
        if(err.response?.status == 401){
          redirectToLogin();
        }
          toast({ position: 'top-center', title: t("assign_project_failed"), description:err.response?.data.message, status: 'error', duration: 5000, isClosable: true,});
      }
    }
  }

  async function handleDeleteUserSubmit(deleteUser) {
    try {
      const updateResponse = await axios.delete(process.env.REACT_APP_BASE_URL + "/users/" + deleteUser.username,{withCredentials: true});
      if(updateResponse.status == 200){
        toast({ position: 'top-center', title: t("delete_user_success"), status: 'success', duration: 5000, isClosable: true,});
        const users = await fetchUsers();
        setUsers(users);
      }
    }
    catch (err){
      console.log(err);
      if(err.response){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        else{
          toast({ position: 'top-center', title: t("delete_user_failed"),description:err.response.data.message , status: 'error', duration: 5000, isClosable: true,});
        }
      }

    }
  }

  return (
    <>
      <Header />
      <Navigatebar page={t("niti_user_management")} />
      <Container maxW="1200px" mt={10}>
        <Heading fontSize="36">{t("niti_user_management")}</Heading>
        <Flex justify="flex-end">
            <AddUserButton OnCreateUserSubmitAsync={handleCreateUserSubmit}/>
        </Flex>
        <Flex justify="center" w="auto">
          <UserManagementTable projects={projects} users={users} onDelete={handleDeleteUserSubmit} onEdit={handleEditUserSubmit} onAssign={handleAssignSubmit} user_name={auth.user.username} />
        </Flex>
      </Container>
    </>
  );
};

export default UserManagment;
