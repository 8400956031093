/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Heading,
  Flex,
  Input,
  Text,
  Divider,
  Image,
  FormControl,
  FormLabel,
  useToast
} from "@chakra-ui/react";
import UsersSelect from '../components/project/UsersSelect';

import { AuthContext } from '../contexts/AuthContext';
import PropTypes from 'prop-types';
import {FieldikPassword, Fieldik} from '../components/form/Fieldik'
import { Formik ,Form,Field } from 'formik';
import * as util from '../util';
import * as validator from './validator/validator'
import { useTranslation } from "react-i18next";

function EditProject({_id}) {
  const toast = useToast();
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const [project, setProject] = React.useState({});
  const [masterPlanImage, setMasterPlanImage] = React.useState("");
  const [logoImage, setLogoImage] = React.useState("");
  const [users, setUsers] = React.useState({});
  const { t } = useTranslation();
  const {
    validateProjectId,
    validateProjectName
  } = validator.useValidation();

  React.useEffect(() => {
    async function fetchData() {
      try{
        const res = await axios.get(process.env.REACT_APP_BASE_URL + "/projects/" + _id ,{withCredentials: true});
        setProjectData(res.data);
      }
      catch(err){
        if(err.response?.status === 401){
          redirectToLogin();
        }
        console.error(err);
      }
    }
    fetchData()
  }, []);

  function redirectToLogin(){
    console.log('redirect to login state /editproject/' + _id);
    if(auth.user.role === 'user_management'){
      navigate('/Login', {state : { path: "/editproject/" + _id }});
    } 
    else{
      navigate('/rolehome');
    }
  }

  function setProjectData(project) {
    setProject(project);
    setMasterPlanImage(project.image);
    setLogoImage(project.logo);
    const list = [];
    setUsers(project.users);
  }

  async function handleSubmit(values,actions) {
    const editProject = {
      projectId : values.projectId,
      projectName : values.projectName,
      image: masterPlanImage,
      logo: logoImage
    };
    try{
      const result = await axios.put(process.env.REACT_APP_BASE_URL + "/projects/" + _id, editProject,{withCredentials: true})
      if(result.status === 200){
        setProject(result.data);
        toast({ position: 'top-center', title: t("edit_project_success"),description:`${result.data.projectName}`, status: 'success', duration: 5000, isClosable: true,});
        navigate('/dashboard');
      }
    }
    catch(err){
      console.error(err);
      if(err.response){
        if(err.response?.status === 401){
          redirectToLogin();
        }
        else{
          toast({ position: 'top-center', title: t("edit_project_failed"),description:`${err.response?.data?.message}`, status: 'error', duration: 5000, isClosable: true,});
        }
      }
    }
  }

  async function handleGetTheMasterPlanImage(e) {
    try {
      const file = util.verifyFileSize(e.target.files);
      if(file){
          const base64 = await util.loadFileToBase64(file);
          setMasterPlanImage(base64.split(",")[1]);
      }
      else{
        toast({ position: 'top-center', title: t("size_over"), status: 'error', duration: 5000, isClosable: true,});
      }
    }
    catch(err){
      toast({ position: 'top-center', title: t("image_upload_failed"),description:err, status: 'error', duration: 5000, isClosable: true,});
    }
  }

  async function handleGetTheLogoImage(e) {
    try {
      const file = util.verifyFileSize(e.target.files);
      if(file){
          const base64 = await util.loadFileToBase64(file);
          setLogoImage(base64.split(",")[1]);
      }
      else{
        toast({ position: 'top-center', title: t("size_over"), status: 'error', duration: 5000, isClosable: true,});
      }
    }
    catch(err){
      toast({ position: 'top-center', title: t("image_upload_failed"),description:err, status: 'error', duration: 5000, isClosable: true,});
    }
  }

  return (
    <>
      <Container maxW="1200px">
        <Flex align="center">
          <Heading fontSize="36px">
            {t("manage_project")} : {project.projectName}
          </Heading>
          {project.logo !== undefined && <Image ml={5} h="30px" src={`data:image/png;base64,${project.logo}`} />}
        </Flex>
        <Flex justify="space-between">
          <Box mr="10px" boxSize="50%">
          {project.image !== undefined &&<Image src={`data:image/png;base64,${project.image}`} />}
          </Box>
          
          <Box boxSize="50%">
           {project._id && 
          <Formik initialValues={{ projectId: project.projectId, projectName:project.projectName, users:users}} onSubmit={handleSubmit}>
            {(props) => (
            <Form>
              <Flex justify="center">
                <Box w="50%" mr={5}>
                  <Fieldik name="projectId" validate={validateProjectId} type="input" label={t("project_code")} variant="flushed"></Fieldik>
                </Box>
                <Box w="50%">
                  <Fieldik name="projectName" validate={validateProjectName} type="input" label={t("project_name")} variant="flushed"></Fieldik>
                </Box>
              </Flex>
              <Flex justify="space-between">
              <Flex flexDir="column" w="50%">
                <Box w="100%">
                <Text color="red" fontSize="16px">
                  {t("size_not_over")}
                </Text>
                  <Text mb={3} fontSize="16px">
                  {t("project_image")} (Masterplan)
                  </Text>
                  {!masterPlanImage ? (
                    <Box></Box>
                  ) : (
                    <Image my={2} src={`data:image/png;base64,${masterPlanImage}`} h="100px" />
                  )}
                  
                  <label className="button" htmlFor="upload-image">{t("select_image")}</label>
                  <Input h={0} opacity="0" id="upload-image" type="file" accept=".png,.jpeg,.jpg" onChange={(e) => {
                      handleGetTheMasterPlanImage(e);
                      props.setFieldValue('upload-image',util.guid(), false);
                    }
                  }/>
                  
                </Box>
              </Flex>
              <Flex flexDir="column" w="50%">
                <Box w="100%">
                <Text color="red" fontSize="16px">
                  {t("size_not_over")}
                </Text>
                  <Text mb={3} fontSize="16px">
                    {t("project_logo")}
                  </Text>
                  {!logoImage ? (
                    <Box></Box>
                  ) : (
                    <Image
                      my={2}
                      src={`data:image/png;base64,${logoImage}`}
                      h="100px"
                    />
                  )}
                <label className="button" htmlFor="upload-logo">{t("select_logo")}</label>
                <Input h={0} opacity="0" id="upload-logo" type="file" accept=".png,.jpeg,.jpg" onChange={(e) => {
                      handleGetTheLogoImage(e);
                      props.setFieldValue('upload-logo',util.guid(), false);
                    }
                  }/>
                </Box>
              </Flex>
            </Flex>
            
            <Flex mt={1} justifyContent="end">
              <Button  type='submit' colorScheme='blue' mr={3} isLoading={props.isSubmitting}
              disabled={!(props.isValid && props.dirty)}
              >
              {t("submit")}</Button>
            </Flex>  
            </Form>
            )}
          </Formik>}
          <Divider my={1} />
          {users?.length > 0 && <UsersSelect users={users}/>}
            
          </Box>
        </Flex>
      </Container>
    </>
  );
}

export default EditProject;

EditProject.propTypes = {
  _id:PropTypes.string
}
