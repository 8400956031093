import React from "react";
import ActionButtons from './ActionButtons'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  chakra,
  Icon,
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Badge,
  Avatar,
  IconButton,
  Select,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import { BsPersonFill } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { AiFillTag } from "react-icons/ai";
import { AiOutlineInteraction } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { useTable, usePagination, useSortBy } from "react-table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProjectActionButtons from '../components/ProjectActionButtons';
import placeholderimage from '../images/placeholder-project.png';
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function ProjectTable() {
  const navigate = useNavigate();
  const bgHead = useColorModeValue("gray.200", "gray.700");
  const bgBody = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const border = useColorModeValue("1px solid #E2E8F0", "");
  const [projects, setProjects] = React.useState([]);
  const auth = React.useContext(AuthContext);
  const [action, setAction] = React.useState();
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchHardware = async () => {
      try{
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/projects/",{withCredentials: true});
        setProjects(response.data);
      }
      catch(err){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        console.log(err);
      }
    };
    fetchHardware();
  }, [auth]);

  function redirectToLogin(){
    console.log('redirect to login state /projectmanagement/');
    if(auth.user.role == 'user_management'){
      navigate('/Login', {state : { path: "/projectmanagement/"}});
    } 
    else{
      navigate('/rolehome');
    }
  }

  function formatDate(date) {
    const d = new Date(date).toLocaleString();
    return d;
  }

  function handleAction(action) {
    const fetchHardware = async () => {
      try{
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/projects/",{withCredentials: true});
        setProjects(response.data);
      }
      catch(err){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        console.log(err);
      }
    };
    fetchHardware();
  }

  const data = React.useMemo(() => [...projects], [projects]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("project_name"),
        accessor: "projectName",
      },
      {
        Header: t("project_code"),
        accessor: "projectId",
      },
      {
        id: "created_at",
        Header: t("create_date"),
        accessor: d => formatDate(d.created_at),
      },
      {
        Header: t("action"),
        accessor: "action",
      }
    ],
    [projects ,t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: false, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      //pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Table
        mt={2}
        w="1200px"
        border={border}
        borderColor="gray.200"
        borderRadius="10px"
        {...getTableProps()}
      >
        <Thead bg={bgHead} color={color}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  <Flex justifyContent="center" color={color} align="center" flexDir="row">
                    <chakra.span pr="1">
                      {column.id == "projectName" ? (
                        <Icon
                          w={4}
                          h={4}
                          transform="translateY(2px)"
                          as={BsPersonFill}
                        />
                      ) : column.id == "projectId" ? (
                        <UnlockIcon w={4} h={4} transform="translateY(-3px)" />
                      ) : column.id == "created_at" ? (
                        <Icon w={4} h={4} as={AiFillTag} />
                      ) : column.id == "action" ? (
                        <Icon w={4} h={4} as={BiUserCircle} />
                      ):null}
                    </chakra.span>
                    {column.render("Header")}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody bg={bgBody} color={color} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                  <Td {...cell.getCellProps()}>
                    
                  {cell.column.id == "projectName" &&
                    <Flex>
                      {cell.row.original.logo ? 
                      <Image w={16} h={16} size="sm" src={`data:image/png;base64,${cell.row.original.logo}`}/>:
                      <Image w={16} h={16} size="sm" src={placeholderimage}/>}
                      <Flex align="center" ml="3">
                        <Text fontWeight="bold">{cell.render("Cell")}</Text>
                      </Flex>
                    </Flex>}
                    <Flex justifyContent="center" color={color} align="center" flexDir="row">
                  {cell.column.id == "projectId" && 
                  <Badge variant='outline' colorScheme={cell.value=='admin'?'red':'green'}>{cell.render("Cell")}</Badge>}
                  {cell.column.id == "created_at" &&
                    <Badge variant="solid" colorScheme="blue">
                      {cell.render("Cell")}
                    </Badge>}
                  {cell.column.id == "action" &&
                    <ProjectActionButtons onAction={(action) => handleAction()} projects={projects} rowProject ={cell.row.original}/>}
                    </Flex>
                  </Td>
                )
                })}
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan="100000">
              <Flex justify="space-between" align="center">
                <Flex
                  w="230px"
                  justify="space-between"
                  align="center"
                  shrink="0"
                >
                  <Text>Show rows per page</Text>

                  <Select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                    placeholder=""
                    w="75px"
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex w="180px" justify="space-between" align="center">
                  <Text>{`Page ${pageIndex + 1} of ${pageCount}`}</Text>
                  <IconButton
                    aria-label="Previous Page"
                    bg={bgBody}
                    icon={<ChevronLeftIcon color={color} />}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <IconButton
                    aria-label="Next Page"
                    bg={bgBody}
                    color={color}
                    icon={<ChevronRightIcon />}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Flex>
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </>
  );
}

export default ProjectTable;
