import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import ProjectsList from "./Projects";
import axios from "axios";
import { AuthContext } from "../../src/contexts/AuthContext";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  chakra,
  Icon,
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Badge,
  Avatar,
  IconButton,
  Select,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  UnlockIcon,
} from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

const HardwareStatus = () => {
  const auth = useContext(AuthContext);
  const bgHead = useColorModeValue("gray.200", "gray.700");
  const bgBody = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const border = useColorModeValue("1px solid #E2E8F0", "");
  const [hardwares, setHardwares] = useState([]);
  const [pid, setPid] = useState("");
  const { t } = useTranslation();
  
  const redirectToLogin = () => {
    console.log("redirect to login state /selectposition/");
    if (auth.user.role == "admin") {
      navigate("/Login", { state: { path: "/selectposition/" } });
    } else if (auth.user.role == "user_management") {
      navigate("/Login", { state: { path: "/usermanagement/" } });
    } else if (auth.user.role == "niti" || auth.user.role == "security") {
      navigate("/Login", { state: { path: "/nitiusermanagement/" } });
    } else {
      navigate("/rolehome");
    }
  };

  const handleProjectSelect = (projectId) => {
    try {
      const selectedPid = projectId;
      setPid(selectedPid);
      fetchData(selectedPid);
    } catch (err) {
      console.log(err);
    }
  };

  async function fetchData(pid) {
    try {
      pid = pid || (pid !== "" ? pid : auth.project._id);
      const records = [];
      if (pid) {
        const res = await axios.get(
          process.env.REACT_APP_BASE_URL + "/hardwaresStatus/" + pid,
          { withCredentials: true }
        );
        res.data.forEach((item) => {
          const data = {
            address: item.address,
            status: item.isOnline,
            last_online: item.last_online,
          };
          records.push(data);
        });

        // Preserve the original order of data
        const sortedRecords = records.map((record, index) => ({
          ...record,
          originalIndex: index,
        }));

        setHardwares([...sortedRecords]);
      }
    } catch (err) {
      console.log(err);
    }
  }
  // useEffect to call fetchData initially and set up the interval
  useEffect(() => {
    try {
      fetchData(pid);

      // Set up interval to call fetchData every minute
      const intervalId = setInterval(() => {
        fetchData(pid);
      }, 30000); // 60000 milliseconds = 1 minute

      // Clean up the interval when the component is unmounted
      return () => clearInterval(intervalId);
    } catch (err) {
      if (err.response?.status == 401) {
        redirectToLogin();
      }
      console.log(err);
    }
  }, [pid]); // useEffect will run whenever pid changes

  const data = useMemo(() => hardwares, [hardwares]);

  const columns = useMemo(
    () => [
      {
        Header: t("address"),
        accessor: "address",
      },
      {
        Header: t("hardware_status"),
        accessor: "status",
      },
      {
        Header: t("last_online"),
        accessor: "last_online",
      },
    ],
    [hardwares, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: false, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      //pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Flex direction="column" align="left" paddingTop={10}>
        {auth.user.role === "admin" || auth.user.role === "user_management" ? (
          <ProjectsList onProjectSelect={handleProjectSelect} />
        ) : (
          <div></div>
        )}
        <Table
          className="table-tiny"
          mt={10}
          w="800px"
          border={border}
          borderColor="gray.200"
          borderRadius="10px"
          {...getTableProps()}
        >
          <Thead bg={bgHead} color={color}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {/* Add a Th for the "no" column */}
                <Th>{t("number")}</Th>
                {headerGroup.headers.map((column) => (
                  <Th
                    key={column.accessor}
                    {...column.getHeaderProps(
                      column.id === "no"
                        ? {} // No sorting props for the "no" column
                        : column.getSortByToggleProps()
                    )}
                    isNumeric={column.isNumeric}
                  >
                    <Flex color={color} align="center" flexDir="row">
                      {column.render("Header")}
                      <chakra.span pl="20">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody bg={bgBody} color={color} {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={row.id}>
                  <Td style={{ padding: "8px", fontSize: "16px" }}>
                    {pageIndex * pageSize + index + 1}
                  </Td>
                  {row.cells.map((cell) => (
                    <Td
                      style={{ fontSize: "16px" }}
                      key={cell.row.original._id}
                      {...cell.getCellProps()}
                    >
                      {cell.column.id === "address" ? (
                        <Badge colorScheme="teal">{cell.render("Cell")}</Badge>
                      ) : cell.column.id === "status" ? (
                        cell.value === "Online" ? (
                          <Badge variant="outline" colorScheme="green">
                            {cell.render("Cell")}
                          </Badge>
                        ) : (
                          <Badge variant="outline" colorScheme="red">
                            {cell.render("Cell")}
                          </Badge>
                        )
                      ) : cell.column.id === "last_online" ? (
                        <Badge colorScheme="blue">{cell.render("Cell")}</Badge>
                      ) : (
                        <Text
                          w={128}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          cursor="pointer"
                          whiteSpace="nowrap"
                          // onClick={() => {
                          //   setRemark(cell.value);
                          //   onRemarkOpen();
                          // }}
                        >
                          {cell.render("Cell")}
                        </Text>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>

          <Tfoot>
            <Tr>
              <Td colSpan="100000">
                <Flex justify="space-between" align="center">
                  <Flex
                    w="230px"
                    justify="space-between"
                    align="center"
                    shrink="0"
                  >
                    <Text>Show rows per page</Text>

                    <Select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                      placeholder=""
                      w="75px"
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <Flex w="180px" justify="space-between" align="center">
                    <Text>{`Page ${pageIndex + 1} of ${pageCount}`}</Text>
                    <IconButton
                      aria-label="Previous Page"
                      bg={bgBody}
                      icon={<ChevronLeftIcon color={color} />}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    />
                    <IconButton
                      aria-label="Next Page"
                      bg={bgBody}
                      color={color}
                      icon={<ChevronRightIcon />}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    />
                  </Flex>
                </Flex>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Flex>
    </>
  );
};

export default HardwareStatus;
