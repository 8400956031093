import { useErrorMessages } from '../enum/errorMessages'

export function useValidation() {
    const THAI = new RegExp('[ก-๙]');
    const errorMessages = useErrorMessages();


    const validateProjectId = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_PROJECT_ID_EMPTY
        }
        return error;
    }

    const validateProjectName = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_PROJECT_NAME_EMPTY
        }
        return error;
    }

    const validateUserCode = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_USER_CODE_EMPTY
        }
        return error;
    }

    const validateName = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_USER_NAME_EMPTY
        }
        return error;
    }

    const validateUserName = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_USER_USERNAME_EMPTY
        }
        else if (THAI.test(value)) {
            error = errorMessages.ERROR_USER_USERNAME_THAI
        }
        return error;
    }

    const validateRole = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_USER_ROLE_EMPTY
        }
        return error;
    }

    const validatePassword = (value, password) => {
        let error
        if (!value) {
            error = errorMessages.PASSWORD_INVALID
        } else if (value.length < 4) {
            error = errorMessages.PASSWORD_TOO_SHORT
        }
        else if (THAI.test(password)) {
            error = errorMessages.PASSWORD_THAI
        }
        return error;
    }

    const validateVPassword = (value, password) => {
        let error
        if (!value) {
            error = errorMessages.PASSWORD_INVALID
        }
        else if (value != password) {
            error = errorMessages.PASSWORD_MISMATCH
        }
        return error;
    }

    const validateMacaddress = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_MACADDRESS_EMPTY
        }
        else if (value.length != 12) {
            error = errorMessages.ERROR_MACADDRESS_NOT_VALID
        }
        else if (THAI.test(value)) {
            error = errorMessages.ERROR_MACADDRESS_THAI
        }
        return error;
    }

    const validateSerialBoard = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_SERIAL_BOARD_EMPTY
        }
        return error;
    }

    const validateAddress = (value) => {
        let error
        if (!value) {
            error = errorMessages.ERROR_ADDRESS_EMPTY
        }
        return error;
    }

    const validateInspector = (value) => {
        let error
        if (!value || value === '') {
            error = errorMessages.ERROR_INSPECTOR_EMPTY
        }
        return error;
    }

    const validateReason = (value) => {
        let error
        if (!value || value === '') {
            error = errorMessages.ERROR_REASON_EMPTY
        }
        return error;
    }

    return {
        validateProjectId,
        validateProjectName,
        validateUserCode,
        validateName,
        validateUserName,
        validateRole,
        validatePassword,
        validateVPassword,
        validateMacaddress,
        validateSerialBoard,
        validateAddress,
        validateInspector,
        validateReason
    };
}

