import React from "react";
import {
    Button, Container, Heading, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, Flex, Image, Badge
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ViewUser = ({ users, isOpen, onOpen, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered >
            <ModalOverlay />
            <ModalContent >
                <ModalHeader fontSize="24px">
                    {t("view_users")}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody borderRadius={8}>
                    <Flex fontSize={12} justifyContent="center" alignContent="center" flexDir="column">
                        {users.map(u => {
                            return (
                                <Flex key={u._id} >
                                    <Badge colorScheme="blue">{t("username")}</Badge>
                                    <Text mr={3} fontSize="16px">{u.username}</Text>
                                    <Badge colorScheme="blue">{t("name")}</Badge>
                                    <Text mr={3} fontSize="16px">{u.name}</Text>
                                </Flex>
                            )
                        })}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ViewUser;