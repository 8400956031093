import { startTransition } from "react";
import i18n from '../i18n';

export const changeLanguage = (lng) => {
  // console.log(`Changing language to: ${lng}`);
  startTransition(() => {
    i18n
      .changeLanguage(lng)
      .then(() => {
        // console.log(`Language changed to: ${lng}`);
      })
      .catch((error) => {
        // console.error(`Error changing language to: ${lng}`, error);
      });
  });
};