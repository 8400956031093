import React from 'react'
import {
  chakra, Divider, Center, IconButton, Tooltip, Flex, Box, Button, Container, FormErrorMessage, Heading, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
  ModalCloseButton, Select, useDisclosure, FormLabel, Input, FormControl
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { AuthContext } from '../contexts/AuthContext'
import PropTypes from 'prop-types';
import ModalBase from './modal/ModalBase';
import { Formik, Form, Field } from 'formik';
import { Fieldik, FieldikPassword } from '../components/form/Fieldik';
import * as validator from './validator/validator'
import { useTranslation } from "react-i18next";

const AddUserButton = ({ OnCreateUserSubmitAsync }) => {
  const [password, setPassword] = React.useState("")
  const auth = React.useContext(AuthContext);
  const { t } = useTranslation();
  const {
    validateUserCode,
    validateName,
    validateUserName,
    validateRole,
    validatePassword,
    validateVPassword
  } = validator.useValidation();

  //OpenModule
  //Create User
  const {
    isOpen: isCreateUserOpen,
    onOpen: onCreateUserOpen,
    onClose: onCreateUserClose,
  } = useDisclosure();

  const handleClick = () => {
    onCreateUserOpen();
  }

  async function handleSubmit(values, actions) {
    const newUser = {
      name: values.name,
      code: values.code,
      username: values.username,
      password: values.password,
      role: values.role
    };
    const result = await OnCreateUserSubmitAsync(newUser);
    if (result) {
      actions.setSubmitting(false);
      onCreateUserClose();
    }
  }

  return (
    <>
      <Button onClick={() => handleClick()} leftIcon={<AddIcon />} >{t("create_user")}</Button>
      <ModalBase onClose={onCreateUserClose} isOpen={isCreateUserOpen} isCentered
        header={
          <>
            <chakra.span color="green.500">{t("add")}</chakra.span>{" "}
            {t("add_new_niti")}
          </>
        }>
        <Formik initialValues={{ name: '', code: '', role: '', username: '', userId: '', password: '', vpassword: '' }} onSubmit={handleSubmit}>
          {(props) => {

            return (
              <Form>
                <Flex justify="space-around" flexDir="column">
                  <Heading fontSize="18px">
                    {t("please_input")}
                  </Heading>
                  <Fieldik name="name" validate={validateName} type="input" label={t("name_surname")} variant="flushed"></Fieldik>
                  <Fieldik name="code" validate={validateUserCode} type="input" label={t("id_code")} variant="flushed"></Fieldik>
                  {auth.user?.role != 'niti' && 
                  <Fieldik name='role' validate={validateRole}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.role}>
                        <Select m={2} onChange={(e) => props.setFieldValue('role', e.target.value, true)}>
                          <option value=''>{t("select_role")}</option>
                          <option value='security'>Security (เจ้าหน้าที่รักษาความปลอดภัย)</option>
                          <option value='niti'>Niti (นิติบุคคล)</option>
                          <option value='admin'>Admin (ผู้ดูแลระบบ)</option>
                          <option value='user_management'>User management (ผู้ดูแลระบบสูงสุด)</option>
                          <option value='lift'>Lift (ผู้ดูแลลิฟท์)</option>
                        </Select>
                        <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                      </FormControl>
                    )}</Fieldik>}
                  <Fieldik name="username" validate={validateUserName} type="input" label={t("account_name")} variant="flushed"></Fieldik>
                  <FieldikPassword name='password' label={t("new_password")} enableShowButton={true} validate={(value) => {
                    validatePassword(value, password);
                    setPassword(value);
                  }} >
                  </FieldikPassword>
                  <FieldikPassword name='vpassword' validate={(value) => validateVPassword(value, password)} label={t("confirm_password")}></FieldikPassword>
                  <Flex mt={2} justifyContent="end">
                    <Button type='submit' colorScheme='teal' mr={3} isLoading={props.isSubmitting} disabled={!(props.isValid && props.dirty)}>
                      {t("submit")}
                    </Button>
                    <Button colorScheme="gray" onClick={onCreateUserClose}>
                      {t("cancel")}
                    </Button>
                  </Flex>
                </Flex>
              </Form>)
          }}
        </Formik>
      </ModalBase>
    </>
  )
}

export default AddUserButton;

AddUserButton.propTypes = {
  OnCreateUserSubmitAsync: PropTypes.func,
}