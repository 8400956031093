import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    FormHelperText,
    Flex
} from '@chakra-ui/react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import * as util from '../../util';
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-unused-vars
const Fieldik = (props) => {
    const guid = util.guid();
    return (
        <Field name={props.name} validate={props.validate}>
            {({ field, form }) => (
                <FormControl isInvalid={(form.errors[props.name] && form.touched[props.name]) || props.isInvalid}>
                    <FormLabel htmlFor={props.name + guid}>{props.label}</FormLabel>
                    <Input {...field} id={props.name + guid} type={props.type} variant={props.variant} placeholder={props.placeholder || props.label} />
                    <FormErrorMessage>{form.errors[props.name]}</FormErrorMessage>
                    {!form.errors[props.name] && <FormHelperText>
                        {props.helper || ''}
                    </FormHelperText>}
                </FormControl>
            )}
        </Field>
    )
}

Fieldik.propTypes = {

    name: PropTypes.string,
    validate: PropTypes.func,
    isInvalid: PropTypes.bool,

    label: PropTypes.string,

    variant: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    helper: PropTypes.string,

}

// eslint-disable-next-line no-unused-vars
const FieldikPassword = (props) => {

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const { t } = useTranslation();

    return (
        <Field name={props.name} validate={props.validate}>
            {({ field, form }) => (
                <FormControl isInvalid={(form.errors[props.name] && form.touched[props.name]) || (props.isInvalid || false)}>
                    <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
                    <InputGroup size='md'>
                        <Input {...field} id={props.name} type={props.enableShowButton ? show ? 'text' : 'password' : 'password'} variant={props.variant} placeholder={props.placeholder || props.label} />
                        <InputRightElement width='4.5rem'>
                            {props.enableShowButton && <Button h='1.75rem' size='sm' onClick={handleClick}>
                                {show ? t("hide") : t("show")}
                            </Button>}
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{form.errors[props.name]}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    )
}

FieldikPassword.propTypes = {
    isInvalid: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    validate: PropTypes.func,

    variant: PropTypes.string,
    placeholder: PropTypes.string,

    enableShowButton: PropTypes.bool,
}

const FieldikRadio = (props) => {

    return (
        <Flex>
            <Field type="radio" name={props.name} value={props.value || ''} validate={props.validate} />
            <label style={{ marginLeft: "8px" }}>{props.value || ''}</label>
        </Flex>
    )
}

FieldikRadio.propTypes = {
    isInvalid: PropTypes.bool,
    name: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.string,
}

const FieldikSelect = (props) => {
    const { t } = useTranslation();
    return (
        <FormControl isInvalid={(props.form.errors[props.name] && props.form.touched[props.name]) || (props.isInvalid || false)}>
            <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
            {props.data && <Select m={2} onChange={props.field.onChange}>
                <option value=''>{t("select_on_site_inspector")}</option>
                {props.data.map((d) => (
                    <option key={d._id} value={d._id}>{d.username}</option>
                ))}
            </Select>}
            <FormErrorMessage>{props.form.errors[props.name]}</FormErrorMessage>
        </FormControl>
    )
}

FieldikSelect.propTypes = {
    isInvalid: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    validate: PropTypes.func,
    form: PropTypes.any,
    field: PropTypes.any,
    variant: PropTypes.string,
    placeholder: PropTypes.string,

    enableShowButton: PropTypes.bool,
}

export { FieldikPassword, Fieldik, FieldikRadio, FieldikSelect }
